import React from 'react';
import Form from 'react-bootstrap/Form'

const row = (props) => {
    return (
        <Form.Row>
            {props.children}
        </Form.Row>
    )
}

export default row;