import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { darkGray } from '../../conf';

const summaryCarInfo = (props) => {

    const adaptCategory = (category) => {
        if (category === 'usate') {
            return 'Usato';
        }
        if (category === 'km_0') {
            return 'KM 0'
        }
        if (category === 'aziendali') {
            return 'Aziendale'
        }
        return 'Categoria non specificata'
    }

    const adaptKm = (km) => {
        if (km.length <= 3) {
            return km
        }
        return km.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    return (
        <div>
            <Row style={{ paddingLeft: "40px", paddingTop: "10px", color: "white", backgroundColor: darkGray, height: "50px" }}>
                <h5><strong>PREZZO</strong></h5>
                <Col>
                    <div style={{ float: "right", marginBottom: "12px" }}>
                        <h5><strong>€ {adaptKm(props.car.prezzo)}</strong></h5>
                    </div>
                </Col>
            </Row>
            <Row style={{ paddingLeft: "30px", paddingTop: "10px", fontSize: "14px" }}>
                <Col>
                    <div style={{ borderBottom: "1px solid #C0C0C0", marginBottom: "15px" }}>
                        <i className="fas fa-tags"></i>
                        <span style={{ fontWeight: "600", marginLeft: "10px" }}>Categoria</span>
                        <span style={{ float: "right", display: "inline" }}>{adaptCategory(props.car.categoria)}</span>
                    </div>
                    <div style={{ borderBottom: "1px solid #C0C0C0", marginBottom: "15px" }}>
                        <i className="fas fa-car"></i>
                        <span style={{ fontWeight: "600", marginLeft: "10px" }}>Modello</span>
                        <span style={{ float: "right", display: "inline" }}>{props.car.modello}</span>
                    </div>
                    {/* <hr></hr> */}
                    <div style={{ borderBottom: "1px solid #C0C0C0", marginBottom: "15px" }}>
                        <i className="fas fa-gas-pump" style={{ float: "left" }}></i>
                        <span style={{ fontWeight: "600", marginLeft: "10px" }}>Alimentazione</span>
                        <span style={{ float: "right", display: "inline" }}>{props.car.alimentazione}</span>
                    </div>
                    {/* <hr></hr> */}
                    <div style={{ borderBottom: "1px solid #C0C0C0", marginBottom: "15px" }}>
                        <i className="fas fa-cog"></i>
                        <span style={{ fontWeight: "600", marginLeft: "10px" }}>Cilindrata</span>
                        <span style={{ float: "right", display: "inline" }}>{props.car.cilindrata} cm³</span>
                    </div>
                    {/* <hr></hr> */}
                    <div style={{ borderBottom: "1px solid #C0C0C0", marginBottom: "15px" }}>
                        <i className="fas fa-horse"></i>
                        <span style={{ fontWeight: "600", marginLeft: "10px" }}>Potenza</span>
                        <span style={{ float: "right", display: "inline" }}>{props.car.potenza} CV</span>
                    </div>
                    {/* <hr></hr> */}
                    <div style={{ borderBottom: "1px solid #C0C0C0", marginBottom: "15px" }}>
                        <i className="fas fa-arrows-alt"></i>
                        <span style={{ fontWeight: "600", marginLeft: "10px" }}>Cambio</span>
                        <span style={{ float: "right", display: "inline" }}>{props.car.cambio}</span>
                    </div>
                    {/* <hr></hr> */}
                    <div style={{ borderBottom: "1px solid #C0C0C0", marginBottom: "15px" }}>
                        <i className="far fa-calendar"></i>
                        <span style={{ fontWeight: "600", marginLeft: "10px" }}>Anno</span>
                        <span style={{ float: "right", display: "inline" }}>{props.car.anno}</span>
                    </div>
                    {/* <hr></hr> */}
                    <div style={{ borderBottom: "1px solid #C0C0C0", marginBottom: "15px" }}>
                        <i className="fas fa-road"></i>
                        <span style={{ fontWeight: "600", marginLeft: "10px" }}>Km</span>
                        <span style={{ float: "right", display: "inline" }}>{adaptKm(props.car.km)} km</span>
                    </div>
                    {/* <hr></hr> */}
                </Col>
            </Row>
        </div>

    )
}

export default summaryCarInfo;