import React from 'react'
import {strapiUrl} from '../../conf'
import { Container, Row, Col } from 'react-bootstrap'
import Filters from './Filters'
import CardDetailsCard from './CarDetailsCard'

class CarsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cars: null,
            filteredCars: null,
            isFetching: true,
            apply: this.applyFilters.bind(this)
        };
    }

    applyFilters(filters) {
        let newCars = [...this.state.cars]
        if (filters.prezzo !== 'Seleziona') {
            newCars = newCars.filter(car => parseInt(car.prezzo) <= parseInt(filters.prezzo.replace('.', '')));
        }
        if (filters.tipo !== 'Seleziona') {
            newCars = newCars.filter(car => car.categoria.startsWith(filters.tipo.charAt(0).toLowerCase()))
        }
        if (filters.alimentazione !== 'Seleziona') {
            newCars = newCars.filter(car => car.alimentazione.toLowerCase() === filters.alimentazione.toLowerCase())
        }
        if (filters.cambio !== 'Seleziona') {
            newCars = newCars.filter(car => car.cambio.toLowerCase() === filters.cambio.toLowerCase())
        }
        if (filters.km !== 'Seleziona') {
            newCars = newCars.filter(car => parseInt(car.km) <= parseInt(filters.km.replace('.', '').replace(' km', '')));
        }
        if (filters.anno !== 'Seleziona') {
            newCars = newCars.filter(car => parseInt(car.anno) >= parseInt(filters.anno));
        }
        this.setState({filteredCars: newCars})
    }

    async componentDidMount() {
        this.setState({ isFetching: true })
        fetch(strapiUrl + '/cars?_sort=data_nuova:desc')
            .then(response => response.json())
            .then(data => {
                this.setState({ cars: data, filteredCars: data, isFetching: false })
            }
            );
    }

    render() {
        if (this.state.isFetching) {
            return (
                <div>
                <Container>
                    <Row>
                        <Col sm={4}>
                            <Filters applyFilter={this.state.apply}/>
                        </Col>
                        <Col sm={8}>
                        </Col>
                    </Row>
                </Container>
            </div>
            )
        }
        const cards = this.state.filteredCars.filter(car => car.nascondi !== true).map((car, index) => {
            return <CardDetailsCard car={car} key={index} />
        });
        return (
            <div>
                <Container>
                    <Row>
                        <Col sm={4}>
                            <Filters applyFilter={this.state.apply}/>
                        </Col>
                        <Col sm={8}>
                            {cards}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CarsPage;