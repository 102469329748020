export const text1 = 'Per i clienti interessati, diamo la possibilità di un pre-ordine, tramite una proposta d\'acquisto, di seguito meglio specificata, ' +
    'con invio di documento d\'identità e tessera sanitaria e di un acconto tramite bonifico bancario.\n'

export const text2 = 'La proposta d\'acquisto vi permetterà di opzionare la vettura scelta, così da programmare successivamente con noi un appuntamento per visionare l’auto' +
    'di persona e nel caso definire il veicolo entro 5 giorni lavorativi, con il contratto vero e proprio, ed il saldo o il finanziamento della cifra rimanente.'

export const text3 = 'Nel caso in cui non vi sarà possibile recarvi presso di noi, proponiamo sempre nel termine dei 5 giorni lavorativi, la visione dell\'auto con ulteriori foto ' +
    ' a 360° oppure tramite video chiamata.' +
    'Successivamente alla visione della vettura, se la stessa non risponderà in toto alle vostre esigenze e alla descrizione da noi fornita, la proposta d\'acquisto ' +
    'si riterrà nulla e la caparra interamente restituita. Se diversamente, non confermerete in nessun modo dopo il termine prestabilito, si terrà nulla la proposta ' +
    'd\'acquisto e la caparra trattenuta.' +
    'Sarà possibile con un\'offerta vantaggiosa, consegnarvi a domicilio la vettura dopo aver provveduto alla preparazione e al completamento delle pratiche di trapasso.'

export const text4 = 'Rimaniamo a vostra completa disposizione per ulteriori informazioni e chiarimenti contattateci!'