import React from 'react'
import { Media, Button, Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {strapiUrl} from '../../conf'
import {darkGray, middleGray} from '../../conf'
import vendutaImg from '../../assets/venduta_obl.png'

const cardDetailsCard = (props) => {

    const adaptCategory = (category) => {
        if (category === 'usate') {
            return 'Usato';
        }
        if (category === 'km_0') {
            return 'KM 0'
        }
        if (category === 'aziendali') {
            return 'Aziendale'
        }
        return 'Categoria non specificata'
    }

    const adaptKm = (km) => {
        if (km.length <= 3) {
            return km
        }
        return km.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    const venduta =  props.car.venduta ? <div style={{ position: "relative", heigth: "50px", width: "120px", textAlign: "center", marginTop: "-200px", marginLeft: "10px" }}>
                        <img width={250} height={150} src={vendutaImg} alt="venduta"/>
                    </div> : <div></div>
    const margin =  props.car.venduta ? "20px" : "-30px"               

    return (
        <Media style={{ paddingTop: "20px", paddingBottom: "20px", borderBottom: "2px solid #bfbfbf" }}>
            <Container className="no_underline" as={Link} to={"/auto/" + props.car.id} style={{color: "black"}}>
                <Row>
                    <Col sm>
                        <div style={{marginBottom: "20px"}}>
                            <img
                                width={280}
                                height={220}
                                className="mr-3"
                                src={strapiUrl + props.car.cover.url}
                                alt="Generic placeholder"
                            />
                            {venduta}
                            <div style={{ position: "relative", heigth: "50px", width: "120px", backgroundColor: darkGray, textAlign: "center", marginTop: margin, marginLeft: "10px", color: "white", textTransform: "upperCase" }}><strong>{adaptCategory(props.car.categoria)}</strong></div>
                        </div>
                    </Col>
                    <Col sm> 
                        <Media.Body>
                            <strong style={{textTransform: "uppercase", fontSize: "20px"}}><u>{props.car.titolo}</u></strong>
                            <br/>
                            <strong style={{textTransform: "uppercase", fontSize: "12px"}}><u>{props.car.modello}</u></strong>
                            <Container>
                                <Row style={{marginTop: "10px"}}>
                                    <Col>
                                        <p>
                                            {/* <i className="fas fa-caret-right"></i>  {adaptCategory(props.car.categoria)} <br /> */}
                                            <i className="fas fa-caret-right"></i>  {props.car.alimentazione} <br />
                                            <i className="fas fa-caret-right"></i>  {props.car.cilindrata} cm³ <br />
                                            <i className="fas fa-caret-right"></i>  {props.car.potenza} cv
                                        </p>
                                    </Col>
                                    <Col>
                                        <p>
                                            <i className="fas fa-caret-right"></i>  {props.car.cambio} <br />
                                            <i className="fas fa-caret-right"></i>  {props.car.anno} <br />
                                            <i className="fas fa-caret-right"></i>  {adaptKm(props.car.km)} km <br />
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                            {/* <h6>
                                        Prezzo: {adaptKm(props.car.prezzo)} €
                                </h6> */}
                            <Button size="sm" to={"/auto/" + props.car.id} style={{ width: "100%", backgroundColor: middleGray, border: "1px solid #B3B3B3", color: "black" }}><strong>PREZZO: {adaptKm(props.car.prezzo)} €</strong></Button>    
                            <Button variant="secondary" size="sm" to={"/auto/" + props.car.id} style={{ width: "100%", backgroundColor: darkGray, border: "1px solid #B3B3B3"}}><strong>SCHEDA COMPLETA</strong></Button>
                        </Media.Body>
                    </Col>
                </Row>
            </Container>
        </Media>
    );
}

export default cardDetailsCard;