import React from 'react'
import { Container } from 'react-bootstrap'
import { middleGray, darkGray } from '../../conf'

const cometovisitus = () => {
    return (
        <div style={{ width: "100%", paddingTop: "30px", backgroundColor: middleGray }}>
            <Container style={{ textAlign: "center" }}>
                <div style={{ backgroundColor: darkGray, color: "white", margin: "auto" }}>
                    <h3>VIENI A TROVARCI</h3>
                </div>
                <h6>Passa dal nostro showroom, sei sempre il benvenuto</h6>
            </Container>
            <iframe style={{marginBottom: "0px"}}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5452.834658257442!2d9.181058080135678!3d45.56300707345369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786bf1a7180aa0d%3A0x2024a1deb06e1f80!2sAUTO%20LG!5e0!3m2!1sit!2sit!4v1589303026884!5m2!1sit!2sit"
                title="Mappa"
                width="100%"
                height="400px">
            </iframe>
        </div>
    )
}

export default cometovisitus;