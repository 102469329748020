import React from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

const textInputField = (props) => {
    return (
        <Form.Group as={Col} md={props.md} controlId={props.controlId}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control placeholder={props.placeholder} required/>
            <Form.Control.Feedback>Campo riempito correttamente</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Campo non compilato</Form.Control.Feedback>
        </Form.Group>
    )
}

export default textInputField;