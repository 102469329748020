import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import ContattiPage from '../pages/contacts/ContactPage'
import HomePage from '../pages/home/HomePage'
import CarsPage from '../pages/cars/CarsPage'
import WeBuyPage from '../pages/webuy/WeBuyPage'
import ServicesPage from '../pages/services/ServicesPage'
import LoanPage from '../pages/loan/LoanPage'
import CarPage from '../pages/car/CarPage'
import MechanicPage from '../pages/mechanic/MechanicPage'
import ContontoVenditaPage from '../pages/contovendita/ContoVenditaPage'
import PrintableCarPage from '../pages/car/PrintableCarPage'

const main = (props) => {
        return <div id="mainContainerId" style={{ marginTop: "94px", height: "100%"}}>
                <div>
                        <Switch>
                        <Route exact path="/"
                                render={() => <HomePage/>}
                        />
                        <Route exact path="/auto"
                                render={() => <CarsPage />}
                        />
                        <Route exact path="/acquistiamousato"
                                render={() => <WeBuyPage />}
                        />
                        <Route exact path="/servizi"
                                render={() => <ServicesPage />}
                        />
                        <Route exact path="/finanziamento"
                                render={() => <LoanPage />}
                        />
                        <Route exact path="/contatti"
                                render={() => <ContattiPage />}
                        />
                        <Route exact path="/auto/:carId"
                                component={CarPage}
                        />
                        <Route exact path="/auto/stampa/:carId"
                                component={PrintableCarPage}
                        />
                        <Route exact path="/carrozzeria"
                                render={() => <MechanicPage />}
                        />
                        <Route exact path="/contovendita"
                                render={() => <ContontoVenditaPage />}
                        />
                        <Redirect to="/" />
                        </Switch>
                </div>
        </div>
}

export default main;