import React from 'react'
import {strapiUrl} from '../../conf'
import SimilarCars from './SimiliarCars'
import { Container, Row, Col } from 'react-bootstrap'
import Gallery from './Gallery'
import Summary from './Summary'
import Share from './Share'
import { StickyContainer } from 'react-sticky-17'
import EquipmentCard from './EquipmentCard'
import {darkGray} from '../../conf'


class CarPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            car: null,
            isFetching: true
        };
    }

    prettifyDescription(description) {
        let descriptionWithoutSpecialChars = description.replace('\\', '');
        while (descriptionWithoutSpecialChars.includes('\\')) {
            descriptionWithoutSpecialChars = descriptionWithoutSpecialChars.replace('\\', '');
        }

        const splittedDescription = descriptionWithoutSpecialChars.split('\n');
        const newDescription = splittedDescription.map((line, index) => <p key={index}>{line}</p>);
        return newDescription;
    }

    async componentDidMount() {
        this.setState({ isFetching: true })
        fetch(strapiUrl + '/cars/' + this.props.match.params.carId)
            .then(response => response.json())
            .then(data => {
                this.setState({ car: data, isFetching: false })
            }
            );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.carId !== this.props.match.params.carId) {
            this.setState({ isFetching: true })
            fetch(strapiUrl + '/cars/' + this.props.match.params.carId)
                .then(response => response.json())
                .then(data => {
                    this.setState({ car: data, isFetching: false })
                }
                ); 
        }
      }

    render() {
        if (this.state.isFetching) {
            return (
                <div />
            )
        }
        const prettyDescription = this.prettifyDescription(this.state.car.descrizione);
        const equipments = [
            {
                title: "COMFORT",
                eq: this.state.car.comfort
            },
            {
                title: "EXTRA",
                eq: this.state.car.extra
            },
            {
                title: "SICUREZZA",
                eq: this.state.car.sicurezza
            },
            {
                title: "INTRATTENIMENTO",
                eq: this.state.car.intrattenimento
            }
        ]
        const cards = equipments.map((eq, index) => <EquipmentCard key={index} equipment={equipments[index]} />)

        return (
            <div>
                <StickyContainer>
                    <Container style={{ marginTop: "120px", marginBottom: "150px" }}>
                        <Row style={{ borderBottom: "1px solid #ccc" }}>
                            <Col sm={6}>
                                <h2 style={{ marginBottom: "20px", textTransform: "uppercase", backgroundColor: darkGray, color: "white", width: "75%", textAlign: "center"}}>
                                    {this.state.car.titolo}
                                </h2>
                            </Col>
                            <Col sm={6}>
                                <Share />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={7}>
                                <Gallery car={this.state.car} />
                            </Col>
                            <Col sm={5}>
                                <Summary car={this.state.car} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={7}>
                                <h3 style={{backgroundColor: darkGray, color: "white", textAlign: "center"}}>DESCRIZIONE</h3>
                                {prettyDescription}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                            <Col sm={7}>
                                {cards}
                            </Col>
                        </Row>
                    </Container>
                </StickyContainer>
                <SimilarCars currentCar={this.state.car.id} />
            </div>
        );
    }
}

export default CarPage;