import React from 'react'
import { Card, CardDeck} from 'react-bootstrap'

const infoContainer = () => {
    return (
        <CardDeck style={{ marginLeft: "80px", marginRight: "80px", marginTop: "20px", marginBottom: "20px" }}>
                <Card style={{ width: '18rem', marginRight: "20px" }}>
                    <Card.Body>
                        <Card.Title>
                            <span style={{ fontSize: "1.5em", color: "Tomato", marginRight: "20px" }}>
                                <i className="fas fa-map-marker-alt" />
                            </span>
                            Dove ci trovi:
                        </Card.Title>
                        <Card.Text style={{ marginLeft: "45px" }}>
                        <a style={{ textDecoration: "none", color: "inherit" }} href="https://goo.gl/maps/8FM787ft3ZpViqyZ9" target="_blank" rel="noopener noreferrer">Via Fogazzaro 4/6</a><br />
                        <a style={{ textDecoration: "none", color: "inherit" }} href="https://goo.gl/maps/8FM787ft3ZpViqyZ9" target="_blank" rel="noopener noreferrer">Monza (MB),</a> <br />
                        <a style={{ textDecoration: "none", color: "inherit" }} href="https://goo.gl/maps/8FM787ft3ZpViqyZ9" target="_blank" rel="noopener noreferrer">20900, Lombardia, Italia</a>
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ width: '18rem', marginRight: "20px" }}>
                    <Card.Body>
                        <Card.Title>
                            <span style={{ fontSize: "1.5em", color: "teal", marginRight: "20px" }}>
                                <i className="fas fa-phone" />
                            </span>
                            Contatti:
                        </Card.Title>
                        <Card.Text style={{ marginLeft: "50px" }}>
                            Salone: 039 9417693 <br />
                            Mobile: 333 6487387 <br />
                            Email: <a href="mailto: luca.cufone@autolg.it">luca.cufone@autolg.it</a> <br />
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ width: '18rem' }}>
                    <Card.Body>
                        <Card.Title>
                            <span style={{ fontSize: "1.5em", color: "maroon", marginRight: "20px" }}>
                                <i className="far fa-clock" />
                            </span>
                            Orari:
                        </Card.Title>
                        <Card.Text style={{ marginLeft: "50px" }}>
                            Lun-Ven:  09:00-12:30 / 14:30-19:00 <br />
                            Sabato:   09:00 - 12:30 <br />
                            Domenica: chiuso
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
    );
}

export default infoContainer;