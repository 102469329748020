import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Layout from './layout/Layout'
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop'

    ReactDOM.render(
      <BrowserRouter>
        <ScrollToTop />
        <Layout/>
      </BrowserRouter>,
      document.getElementById('root')
    );
  //})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
