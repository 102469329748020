import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {lightGray} from '../conf'

const contactsHeader = () => {
    return (
        <div className="hidden-xs" style={{ fontSize: "10px", height: "25px", backgroundColor: lightGray, position: "sticky", top: "0px", zIndex: "100", color: "#454E55" }}>
            <Container>
                <Row>
                    <Col style={{ width: "50%", float: "left", marginTop: "5px" }}>
                        <i className="fas fa-phone" style={{ marginRight: "5px" }} />
                        333 6487387
                        <i className="fas fa-envelope" style={{ marginRight: "5px", marginLeft: "5px" }} />
                        <a style={{ textDecoration: "none", color: "inherit" }} href="mailto: luca.cufone@autolg.it">luca.cufone@autolg.it</a>
                    </Col>
                    <Col style={{float: "right", marginTop: "5px", textAlign: "right" }}>
                        <i className="fas fa-map-marker-alt" style={{marginRight: "5px"}}/>
                        <a style={{ textDecoration: "none", color: "inherit" }} href="https://goo.gl/maps/8FM787ft3ZpViqyZ9" target="_blank" rel="noopener noreferrer">Via Fogazzaro 4/6, Monza, 20900</a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default contactsHeader;