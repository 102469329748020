import React from 'react'
import { Card, Button, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { strapiUrl } from '../../conf'
import { darkGray, middleGray } from '../../conf'

const carCard = (props) => {

    const adaptCategory = (category) => {
        if (category === 'usate') {
            return 'USATO';
        }
        if (category === 'km_0') {
            return 'KM 0'
        }
        if (category === 'aziendali') {
            return 'AZIENDALE'
        }
        return 'Categoria non specificata'
    }

    const addDotToNumber = (number) => {
        if (number.length <= 3) {
            return number
        }
        return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    return (
        <Col xs={props.xs} lg="3">
            <Card className='car_card' as={Link} to={"/auto/" + props.car.id} style={{color: "black", marginBottom: "50px"}}>
                <Card.Img variant="top" src={strapiUrl + props.car.cover.url} />
                <div style={{ heigth: "50px", width: "120px", backgroundColor: darkGray, textAlign: "center", marginTop: "-30px", marginLeft: "10px", zIndex: "50", color: "white" }}><strong>{adaptCategory(props.car.categoria)}</strong></div>
                <Card.Body>
                    <Card.Title style={{ fontSize: "16px", height: "32px" }}><strong style={{ textTransform: "uppercase", borderBottom: "1px solid black" }}>{props.car.titolo}</strong></Card.Title>
                    <Card.Text style={{ fontSize: "14px" }}>
                        <strong>Anno:</strong> {props.car.anno} <br />
                        <strong>Km:</strong> {addDotToNumber(props.car.km)} <br />
                        <strong>Cilindrata:</strong> {addDotToNumber(props.car.cilindrata)} CC<br />
                        <strong>Cambio:</strong> {props.car.cambio} <br />
                        <strong>Alimentazione:</strong> {props.car.alimentazione} <br />
                        {/* <strong>Prezzo:</strong> {props.car.prezzo} € */}
                </Card.Text>
                </Card.Body>
                <Button size="sm" to={"/auto/" + props.car.id} style={{ width: "100%", backgroundColor: middleGray, border: "1px solid #B3B3B3", color: "black" }}><strong>PREZZO: {addDotToNumber(props.car.prezzo)} €</strong></Button>
                <Button size="sm" to={"/auto/" + props.car.id} style={{ width: "100%", backgroundColor: darkGray, border: "1px solid #333333" }}><strong>SCHEDA COMPLETA</strong></Button>
            </Card>
        </Col>
    );
}

export default carCard;