import React from 'react'
import EmptyWhiteRow from './EmptyWhiteRow'
import VideoContainer from './VideoContainer'
import LastCars from './LastCars'
import NewServices from './NewServices'
import Reviews from './Reviews'
import ComeToVisitUs from './ComeToVisitUs'

const homePage = (props) => {
    return (
        <div>
            <EmptyWhiteRow />
            <VideoContainer />
            <EmptyWhiteRow />
            <LastCars/>
            <NewServices />
            <Reviews />
            <ComeToVisitUs/>
        </div>
    )
}

export default homePage;