
// export const strapiUrl = "http://localhost:1337"
export const strapiUrl = "https://api.autolg.it"
export const darkGray = "#333333"
export const middleGray = "#B3B3B3"
export const lightGray = "#CCCCCC"

// export const darkGray = "#343a40"
// export const middleGray = "#f1f1f2"
//  export const lightGray = "#e4e4e7"

// export const strapiUrl = "https://autolg.herokuapp.com"