import React from 'react'
import {Row, Image, Nav, Navbar} from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';
import logo from '../assets/logo_scudo.png';
import autoscoutLogo from '../assets/autoscout.png';
import { Link } from 'react-router-dom'
import {darkGray} from '../conf'

class Header extends React.Component {

    state = {
        activeTab: 'home'
    }

    updateActiveTab = (tab) => {
        this.setState({activeTab: tab})
    }

    render() {
        return (
            <Navbar fixed="top" collapseOnSelect expand="lg" variant="dark" style={{marginTop: "25px", backgroundColor: darkGray}}>
                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        width="80px"
                        height="7%"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto ml-auto">
                    <Nav.Link as={Link} to="/auto" href="#auto" className={(this.state.activeTab === 'cars' || window.location.href.endsWith('/auto')) ? 'underline' : ''} active={this.state.activeTab === 'cars'} onClick={() => this.updateActiveTab('cars')}><h6 style={{color: "white"}}>LE NOSTRE AUTO</h6></Nav.Link>
                        <Nav.Link as={Link} to="/acquistiamousato" href="#acquistiamousato" className={(this.state.activeTab === 'weBuy' || window.location.href.endsWith('/acquistiamousato')) ? 'underline' : ''} active={this.state.activeTab === 'weBuy'} onClick={() => this.updateActiveTab('weBuy')}><h6 style={{color: "white"}}>ACQUISTIAMO USATO</h6></Nav.Link>
                        {/* <Nav.Link as={Link} to="/carrozzeria" href="#carrozzeria" active={this.state.activeTab === 'mechanics'} onClick={() => this.updateActiveTab('mechanics')}>Carrozzeria</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/contovendita" href="#contovendita" active={this.state.activeTab === 'contovendita'} onClick={() => this.updateActiveTab('contovendita')}>CONTO VENDITA</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/servizi" href="#servizi" className={(this.state.activeTab === 'services' || window.location.href.endsWith('/servizi')) ? 'underline' : ''} active={this.state.activeTab === 'services'} onClick={() => this.updateActiveTab('services')}><h6 style={{color: "white"}}>SERVIZI</h6></Nav.Link> */}
                        <Nav.Link as={Link} to="/finanziamento" href="#finanziamento" className={(this.state.activeTab === 'loan' || window.location.href.endsWith('/finanziamento')) ? 'underline' : ''} active={this.state.activeTab === 'loan'} onClick={() => this.updateActiveTab('loan')}><h6 style={{color: "white"}}>FINANZIAMENTO ONLINE</h6></Nav.Link>
                        <Nav.Link as={Link} to="/contatti" href="#contatti" className={(this.state.activeTab === 'contacts' || window.location.href.endsWith('/contatti')) ? 'underline' : ''} active={this.state.activeTab === 'contacts'} onClick={() => this.updateActiveTab('contacts')}><h6 style={{color: "white"}}>CONTATTI</h6></Nav.Link>
                    </Nav>
                    <Row style={{marginRight: "10px"}}>
                        <a href="https://www.autoscout24.it/concessionari/auto-lg-di-cufone-luca?srcl=1" target="_blank" rel="noopener noreferrer"><Image title="Autoscout24" src={autoscoutLogo} roundedCircle style={{ width: "45px", marginRight: "10px" }} /></a>
                        <SocialIcon title="Whatsapp" style={{ marginRight: "10px", height: 45, width: 45 }} url="https://wa.me/393336487387" network="whatsapp" fgColor={darkGray} bgColor="white" target="_blank" rel="noopener noreferrer" />
                        <SocialIcon title="Instagram" style={{ marginRight: "10px", height: 45, width: 45 }} url="https://www.instagram.com/auto_lg/" fgColor={darkGray} bgColor="white" target="_blank" rel="noopener noreferrer" />
                        <SocialIcon title="Facebook" style={{ marginRight: "10px", height: 45, width: 45 }} url="https://facebook.com/AUTOLG" fgColor={darkGray} bgColor="white" target="_blank" rel="noopener noreferrer" />
                        <SocialIcon title="Youtube" style={{height: 45, width: 45 }} url="https://www.youtube.com/channel/UC2Hg0j_H8HA_snC3GrjfFtg" fgColor={darkGray} bgColor="white" target="_blank" rel="noopener noreferrer" />
                        {/* <a href="https://impresapiu.subito.it/shops/12889-auto-lg?xtfrom=directory" target="_blank" rel="noopener noreferrer"><Image title="Subito" src={subitoLogo} roundedCircle style={{ width: "45px", marginRight: "10px" }} /></a> */}
                    </Row>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Header;