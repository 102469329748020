import React from 'react'
import { Link } from 'react-router-dom'

const contontoVenditaPage = () => {
    return (
        <div style={{ marginLeft: "80px", marginRight: "80px", marginTop: "140px", marginBottom: "20px" }}> 
            <h2>Servizio Conto Vendita</h2>
            Desideri vendere la tua auto da privato ottimizzando così il profitto, senza perdite di tempo extra, 
            senza ricevere svariate telefonate dai probabili acquirenti, mantenendo la privacy del tuo domicilio 
            o posto di lavoro, con qualcuno di fidato e competente che segua la vendita, dalla trattativa, al pagamento 
            fino alla documentazione certificata?
            AUTOLG e' quello che fa per te!
            <br/>
            <Link to="/contatti">Contattaci</Link> , ti illustreremo tutto nei dettagli oppure compila questa <Link to="/acquistiamousato">pagina</Link>  e verrai ricontattato.
            <br/>
            <br/>
            <h5>Consulenza su acquisto e vendita di autoveicoli</h5>
            Vi supporteremo durante tutta l’operazione di acquisto o di vendita, garantendoti assistenza completa 
            ad ogni passo, certificando documentazione e pagamenti.
            <br/>
            <br/>
            <h5>Valutazione del reale valore di mercato della vostra vettura</h5>
            I veicoli verranno valutati con i principali mezzi a disposizione, inoltre effettueremo una 
            ricerca di mercato mirata, che stabilira' il prezzo più competitivo per affrontare il mercato.
            <br/>
            <br/>
            <h5>Vantaggi</h5>
            Uno dei vantaggi della vendita tra "privati" è nel realizzare il profitto netto migliore possibile e 
            di renderlo sicuro da truffe di ogni genere. Inoltre, nel caso di riacquisto, avreste il budget da 
            reinvestire sulla vostra prossima vettura sul venduto ed al netto di spese, al contrario di quando si 
            permuta un usato.
            <br/>
            <br/>
            <h5>Book fotografico della vettura</h5>
            Fotograferemo la vostra vettura assicurandoci di cogliere e di valorizzare tutti gli aspetti più importanti, 
            coprendo le targhe garantendovi la massima privacy.
            <br/>
            <br/>
            <h5>Inserimento del vostro veicolo sui maggiori gestionali d’auto sul web</h5>
            Creeremo annunci relativi al vostro veicolo sul nostro sito web, sui social network e 
            sulle principali piattaforme di compravendita, in modo da massimizzare la visibilità della vostra offerta.
            <br/>
            <br/>
            <h5>Possibilità di lasciare il vostro veicolo presso la nostra sede</h5>
            Su richiesta, in base alle vostre esigenze, è possibile lasciare la vettura che volete vendere, 
            insieme alle nostre, massimizzando la possibilita' di vendita nel miglior tempo possibile, 
            diminuendo i costi di gestione dell'auto con la possibilita' di sospendere l'assicurazione.
            <br/>
            <br/>
            <h5>Possibilità di finanziamento e di acquisto garanzia</h5>
            Su richiesta del cliente, forniremo la nostra consulenza per consentirgli di predisporre richieste 
            di finanziamento, assicurazione e di garanzia, tutto personalizzato.
        </div>
    );
}

export default contontoVenditaPage;