import React from 'react'
import { Card, Col } from 'react-bootstrap'

const reviewCard = (props) => {
    return (
        <Col xs="12" lg="4">
            <Card className={props.className}>
                <Card.Body>
                    <Card.Title style={{ textAlign: "center", fontSize: "16px" }}><strong>{props.title}</strong></Card.Title>
                    <Card.Text style={{ textAlign: "center", fontSize: "14px" }}>
                        <i className="fas fa-star" style={{ color: "#ffe135" }}></i>
                        <i className="fas fa-star" style={{ color: "#ffe135" }}></i>
                        <i className="fas fa-star" style={{ color: "#ffe135" }}></i>
                        <i className="fas fa-star" style={{ color: "#ffe135" }}></i>
                        <i className="fas fa-star" style={{ color: "#ffe135" }}></i>
                        <br />
                        <br />
                        <span>
                            "{props.text}"
                    </span>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default reviewCard;