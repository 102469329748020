import React from 'react'
import ServiceCard from './ServiceCard'
import OnlineSellServiceCard from './OnlineSellServiceCard'
import { lightGray, middleGray } from '../../conf'
import sellImg from '../../assets/sell-online.jpg'
import insuranceImg from '../../assets/insurance.jpg'
import finanziamentoImg from '../../assets/finanziamento.jpg'
import consulenzaImg from '../../assets/consulenza.jpg'
import compriamoImg from '../../assets/compriamo.jpg'
import rentImg from '../../assets/rent.jpg'

const venditaOnlineText = 'Diamo la possibilità a tutti i nostri clienti di acquistare le nostre vetture a distanza da tutte le regioni italiane e anche dagli altri stati europei. Oltre alle foto avrete la possibilità tramite video accurati o videocall di visionare ogni dettaglio del veicolo da voi scelto e così procedere all’acquisto in tutta sicurezza. Su richiesta la vostra nuova auto vi verrà spedita a casa!'
const consulenzaText = 'Consiglieremo voi clienti, privati e aziende, sul percorso d’acquisto più adatto alle vostre necessità. Vi seguiremo durante e dopo il vostro acquisto/vendita in tutte le altre fasi e vostre esigenze, come espletare le pratiche auto, spedizione e ritiro veicolo a domicilio, fornirvi servizi di acquisto e cambio gomme, riparazione e sostituzione cristalli anche a domicilio. Sarà a disposizione la nostra rete di soccorso stradale, officine, elettrauto, carrozzerie e cardeatling e vi guideremo nella scelta del piano finanziario, servizi accessori e assicurativi.'
const consulenzaText2 = 'Consiglieremo voi clienti, privati e aziende, sul percorso d’acquisto più adatto alle vostre necessità tra vetture nuove, km0, aziendali, usate e veicoli commerciali. Vi seguiremo durante e dopo il vostro acquisto/vendita in tutte le altre fasi e vostre esigenze, come espletare le pratiche auto, spedizione e ritiro veicolo a domicilio, fornirvi servizi di acquisto e cambio gomme, riparazione e sostituzione cristalli anche a domicilio. Sarà a disposizione la nostra rete di soccorso stradale, officine, elettrauto, carrozzerie e cardeatling e vi guideremo nella scelta del piano finanziario, servizi accessori e assicurativi.'
const acquistiamoUsatoText = 'Da noi potrete dare in permuta/rottamazione il vostro usato sia per l’acquisto di una vettura usata che per l’acquisto di una vettura nuova, Km0 o aziendale. Valutiamo anche l’acquisto diretto del vostro usato senza l’obbligo di riacquisto. Inoltre potrete lasciare presso i nostri spazi la vostra vettura in conto vendita.'
const noleggioText = 'In alternativa all’acquisto potrete scegliere di noleggiare a breve o a lungo termine la vostra auto. Le aziende ed i soggetti iva potranno valutare anche di essere prima locatari e poi considerare l’acquisto con un FULL LEASING, ovvero la possibilità di rateizzare il veicolo e tutti i servizi che offre un noleggio, ma con l’alternativa del riscatto finale, diventando proprietari al 100% del mezzo.'
const prodottiFinanziariText = 'Potrete scegliere il metodo più adatto a voi per finanziare il vostro acquisto tra finanziamento finalizzato, progetto valore futuro, finanziamenti proposti dai vari marchi automobilistici e leasing. A tutte queste metodologie potrete abbinare i servizi accessori più funzionali alle vostre esigenze.'
const assicurazioniText = 'Negli anni abbiamo creato una rete di collaborazione con le maggiori compagnie assicurative così da creare un’ampia offerta per i nostri clienti. Inoltre possiamo integrare i vari prodotti finanziari con i servizi assicurativi più svariati, come infortunio al conducente, credito protetto, Furto incendio, Kasko ed altri ancora, tutti rateizzabili insieme alla vostra auto.'

class ServicesPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth < 992,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 992
            });
        }, false);
    }

    render() {
        return (
            <div>
                <ServiceCard title="consulenza" text={consulenzaText2} backColor={middleGray} textLayout='left' img={consulenzaImg} anchor="consulenza" />
                <OnlineSellServiceCard title="vendita online" text={venditaOnlineText} backColor={lightGray} textLayout={this.state.isMobile ? 'left' : 'right'} img={sellImg} anchor="vendita" />
                <ServiceCard title="acquistiamo usato" text={acquistiamoUsatoText} backColor={middleGray} textLayout='left' img={compriamoImg} anchor="acquistiamo" />
                <ServiceCard title="noleggio" text={noleggioText} backColor={lightGray} textLayout={this.state.isMobile ? 'left' : 'right'} img={rentImg} anchor="noleggio" />
                <ServiceCard title="prodotti finanziari" text={prodottiFinanziariText} backColor={middleGray} textLayout='left' img={finanziamentoImg} anchor="finanziari" />
                <ServiceCard title="assicurazioni" text={assicurazioniText} backColor={lightGray} textLayout={this.state.isMobile ? 'left' : 'right'} img={insuranceImg} anchor="assicurazioni" />
            </div>

        );
    }

}

export default ServicesPage;