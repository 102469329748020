import React from 'react'
import { Container, Row, Col, Figure, Card, CardDeck } from 'react-bootstrap';
import carrozzeriaImg from '../../assets/carrozzeria_ognibene.jpeg'

const mechanicPage = () => {
    return (
        <div>
            <div style={{ paddingLeft: "80px", paddingRight: "80px", paddingTop: "50px", marginBottom: "20px", backgroundColor: "rgb(241, 241, 242)" }}>
            <h1 style={{ color: "rgb(67, 76, 86)" }}>Carrozzeria Ognibene</h1>
            <Container>
                <Row>
                    <Col sm={7} style={{ marginTop: "80px" }}>
                        La carrozzeria Ognibene è sul territorio dal 1978 e nella sede odierna di Paderno Dugnano dal 1991.
                        Ha rapporti di lavoro con le più grosse concessionarie di automobili e ricambi della zona.
                        Collabora con i maggiori studi di perizie e le agenzie assicurative delle province di Milano e Monza e Brianza. <br />

                        Azienda certificata a norma, per gli impianti e la formazione del personale, come previsto dalla legge.

                        Carrozzeria Ognibene è anche attiva 
                        su <a href="https://www.facebook.com/Carrozzeria-Ognibene-539562429423688/" target="_blank" rel="noopener noreferrer">Facebook</a>, 
                        visita la 
                        nostra <a href="https://www.facebook.com/Carrozzeria-Ognibene-539562429423688/" target="_blank" rel="noopener noreferrer">pagina!</a> <br/>
                    </Col>
                    <Col sm={5}>
                        <Figure style={{ marginTop: "30px" }}>
                            <Figure.Image
                                src={carrozzeriaImg}
                            />
                        </Figure>
                    </Col>
                </Row>
            </Container>
            </div>
            <CardDeck style={{ marginLeft: "80px", marginRight: "80px", marginTop: "20px", marginBottom: "20px" }}>
                <Card style={{ width: '18rem', marginRight: "20px" }}>
                    <Card.Body>
                        <Card.Title>
                            <span style={{ fontSize: "1.5em", color: "Tomato", marginRight: "20px" }}>
                                <i className="fas fa-map-marker-alt" />
                            </span>
                            Dove ci trovi:
                        </Card.Title>
                        <Card.Text style={{ marginLeft: "45px", fontSize: "15px" }}>
                            Via E. De Nicola, 6 <br />
                            Paderno Dugnano (MI), <br />
                            20037, Lombardia, Italia
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ width: '18rem', marginRight: "20px" }}>
                    <Card.Body>
                        <Card.Title>
                            <span style={{ fontSize: "1.5em", color: "teal", marginRight: "20px" }}>
                                <i className="fas fa-phone" />
                            </span>
                            Contatti:
                        </Card.Title>
                        <Card.Text style={{ marginLeft: "50px", fontSize: "15px"  }}>
                            Tel: +39 0299041028 <br />
                            Mobile: +39 3386884317 <br />
                            Mobile: +39 331 9432458 <br />
                            Email: <a href="mailto: ognibene@erreciservice.com">ognibene@erreciservice.com</a> <br />
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ width: '18rem' }}>
                    <Card.Body>
                        <Card.Title>
                            <span style={{ fontSize: "1.5em", color: "maroon", marginRight: "20px" }}>
                                <i className="far fa-clock" />
                            </span>
                            Orari:
                        </Card.Title>
                        <Card.Text style={{ marginLeft: "50px", fontSize: "15px"  }}>
                            Lun-Ven:  08:30-12:00 / 14:00-18:30 <br />
                            Sabato:   08:30 - 12:00 <br />
                            Domenica: chiuso
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
        </div>

    )
}

export default mechanicPage;