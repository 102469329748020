import React from 'react'
import { Container, Row, Spinner, Col, Carousel, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CarCard from './CarCard'
import { strapiUrl } from '../../conf'
import { lightGray, darkGray} from '../../conf'


class LastCars extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth < 600,
            cars: null,
            isFetching: true
        };
    }


    async componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 600
            });
        }, false);

        this.setState({ isFetching: true })
        fetch(strapiUrl + '/cars?_sort=data_nuova:desc&_limit=4')
            .then(response => response.json())
            .then(data => {
                this.setState({ cars: data, isFetching: false })
            }
            );
    }

    render() {
        if (this.state.isFetching) {
            return (
                <div style={{ backgroundColor: lightGray, width: "100%", paddingTop: "30px", paddingBottom: "30px" }}>
                    <Container style={{ textAlign: "center" }}>
                        <div style={{ backgroundColor: darkGray, color: "white", margin: "auto" }}>
                            <h3>I NOSTRI ULTIMI ARRIVI</h3>
                        </div>
                        <h5>Garantiti e certificati</h5>
                        {/* <h6><Link to="/auto" style={{ color: "#585858" }}>Guarda tutte le nostre auto</Link></h6> */}
                        <Button size="sm" as={Link} to="/auto" style={{backgroundColor: darkGray, border: "1px solid #B3B3B3" }}><strong>GUARDA TUTTE LE NOSTRE AUTO</strong></Button>
                    </Container>
                    <Container style={{ width: "50%" }}>
                        <Row>
                            <Col style={{ textAlign: "center" }}>
                                <Spinner animation="border" variant="secondary" />
                            </Col>
                            <Col style={{ textAlign: "center" }}>
                                <Spinner animation="border" variant="secondary" />
                            </Col>
                            <Col style={{ textAlign: "center" }}>
                                <Spinner animation="border" variant="secondary" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }
        const cards = this.state.cars.filter(car => car.nascondi !== true).map((car, index) => {
            return this.state.isMobile ? <Carousel.Item><CarCard car={car} key={index*index} xs = "12"/></Carousel.Item> : <CarCard car={car} key={index} xs="6" />
        });

        const wrappedCards = this.state.isMobile ? <Carousel nextIcon={<span className="carousel-control-next-icon-my" />} prevIcon={<span className="carousel-control-prev-icon-my" />}>{cards}</Carousel> : cards
        
        return (
            <div style={{ backgroundColor: lightGray, width: "100%", paddingTop: "30px", paddingBottom: "30px" }}>
                <Container style={{ textAlign: "center", marginBottom: "50px" }}>
                    <div style={{ backgroundColor: darkGray, color: "white", margin: "auto" }}>
                        <h3>I NOSTRI ULTIMI ARRIVI</h3>
                    </div>
                    <h5>Garantiti e certificati</h5>
                    {/* <h6><Link to="/auto" style={{ color: "#585858" }}>Guarda tutte le nostre auto</Link></h6> */}
                    <Button size="lg" as={Link} to="/auto" style={{backgroundColor: darkGray, border: "1px solid #B3B3B3" }}><strong>GUARDA TUTTE LE NOSTRE AUTO</strong></Button>
                </Container>
                <Container>
                    <Row className="justify-content-md-center">
                        {wrappedCards}
                    </Row>
                </Container>
            </div>
        )
    }
}

export default LastCars;