import React from 'react'
import { Container, Row, Carousel} from 'react-bootstrap'
import {strapiUrl} from '../../conf'
import CarCard from '../../pages/home/CarCard'
import {middleGray, darkGray} from '../../conf'

class SimilarCars extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth < 600,
            currentCar: props.currentCar,
            cars: null,
             isFetching: true
        };
    }

    async componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 600
            });
        }, false);

        this.setState({ isFetching: true })
        fetch(strapiUrl + '/cars')
            .then(response => response.json())
            .then(data => {
                let filteredCars = data.filter(car => car.id !== this.state.currentCar && car.nascondi !== true && car.venduta !== true);
                const shuffled = filteredCars.sort(() => 0.5 - Math.random());
                let selected = shuffled.slice(0, 4);
                this.setState({ cars: selected, isFetching: false })
            }
            );
    }

    render() {
        if (this.state.isFetching) {
            return (
                <div />
            )
        }

        const cards = this.state.cars.map((car, index) => {
            return this.state.isMobile ? <Carousel.Item><CarCard car={car} key={index*index} xs = "12"/></Carousel.Item> : <CarCard car={car} key={index} xs="6" />
        });

        const wrappedCards = this.state.isMobile ? <Carousel>{cards}</Carousel> : cards

        return (
            <div style={{ backgroundColor: middleGray, width: "100%", marginTop: "80px", paddingTop: "30px", paddingBottom: "30px" }}>
                <Container style={{ textAlign: "center" }}>
                <div style={{backgroundColor: darkGray, color: "white", width: "300px", margin: "auto"}}>
                    <h3>ANNUNCI SIMILI</h3>
                </div>
                </Container>
                <Container style={{marginTop: "50px"}}>
                    <Row className="justify-content-md-center">
                        {wrappedCards}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default SimilarCars;