import React from 'react'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import ContactsHeader from './ContacsHeader'
import {lightGray} from '../conf'

const layout = (props) => {
    return (
        <div id="layoutContainerId" style={{height: "100%", backgroundColor: lightGray, fontFamily: "Montserrat, sans-serif" }}>
            <ContactsHeader/>
            <Header />
            <Main />
            <Footer />
        </div>
    );
}

export default layout;