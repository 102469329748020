import React from 'react'
import { Container, Row} from 'react-bootstrap'
import loanIcon from '../../assets/loan.png'
import insuranceIcon from '../../assets/insurance.png'
import consulenceIcon from '../../assets/consulence.png'
import assistanceIcon from '../../assets/assistance.png'
import rentIcon from '../../assets/rent.png'
import papersIcon from '../../assets/papers.png'
import ServiceIcon from './ServiceIcon'
import {middleGray, darkGray} from '../../conf'

const newServices = () => {
    return (
        <div style={{ width: "100%", paddingTop: "30px", paddingBottom: "30px", backgroundColor: middleGray }}>
            <Container style={{ textAlign: "center" }}>
                <div style={{backgroundColor: darkGray, color: "white", margin: "auto"}}>
                    <h3>I NOSTRI SERVIZI</h3>
                </div>
                <h6>Tutto ciò di cui puoi avere bisogno</h6>
            </Container>
            
            <Container style={{ width: "80%"}}>
                <Row>
                    <ServiceIcon title="CONSULENZA" icon={consulenceIcon} anchor="consulenza"/>
                    <ServiceIcon title="VENDITA ONLINE" icon={assistanceIcon} anchor="vendita"/>
                    <ServiceIcon title="ACQUISTIAMO USATO" icon={papersIcon} anchor="acquistiamo"/>
                    <ServiceIcon title="NOLEGGIO" icon={rentIcon} anchor="noleggio"/>
                    <ServiceIcon title="PRODOTTI FINANZIARI" icon={loanIcon} anchor="finanziari"/>
                    <ServiceIcon title="ASSICURAZIONI" icon={insuranceIcon} anchor="assicurazioni"/>
                </Row>
            </Container>
        </div>
    );
}

export default newServices;