import React, { useState } from 'react';
import { Jumbotron, Container, Col, Row, Figure, Modal, Button } from 'react-bootstrap'
import { darkGray } from '../../conf'
import { text1, text2, text3, text4 } from '../car/BuyOnlineText'




const OnlineServiceCard = (props) => {

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    if (props.textLayout === 'left') {
        return (
            <Jumbotron style={{ backgroundColor: props.backColor, marginBottom: "0px" }}>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Grazie!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {text1}
                        <br />
                        <br />
                        {text2}
                        <br />
                        <br />
                        {text3}
                        <br />
                        <br />
                        {text4}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Chiudi
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Container id={props.anchor} className="anchor">
                    <Row>
                        <Col xs="12" lg="7">
                            <h3 style={{ paddingLeft: "10px", backgroundColor: darkGray, color: "white", textTransform: "uppercase", textAlign: "center" }}>{props.title}</h3>
                            {props.text}
                            <br />
                            <a style={{ color: "#212529", cursor: "pointer" }} onClick={handleShow} href="#!"> Clicca qui per maggiori informazioni!</a>
                        </Col>
                        <Col xs="12" lg="5">
                            <Figure>
                                <Figure.Image
                                    src={props.img}
                                />
                            </Figure>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        )
    }
    return (
        <Jumbotron style={{ backgroundColor: props.backColor, marginBottom: "0px" }}>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Grazie!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {text1}
                    <br />
                    <br />
                    {text2}
                    <br />
                    <br />
                    {text3}
                    <br />
                    <br />
                    {text4}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container id={props.anchor} className="anchor">
                <Row>
                    <Col xs="12" lg="5">
                        <Figure>
                            <Figure.Image
                                src={props.img}
                            />
                        </Figure>
                    </Col>
                    <Col xs="12" lg="7">
                        <h3 style={{ paddingLeft: "10px", backgroundColor: darkGray, color: "white", textTransform: "uppercase", textAlign: "center" }}>{props.title}</h3>
                        {props.text}
                        <br />
                        <a style={{ color: "#212529", cursor: "pointer" }} onClick={handleShow} href="#!">Clicca qui per maggiori informazioni!</a>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    );
}

export default OnlineServiceCard;

