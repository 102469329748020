import React from 'react';
import { Container, Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Sticky } from 'react-sticky-17'
import SummaryCarInfo from './SummaryCarInfo'
import { darkGray, middleGray } from '../../conf'
import {text1, text2, text3, text4} from './BuyOnlineText'

class Summary extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: window.innerWidth < 600,
            showAnticipoModal: false,
            showAcquistoModal: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({
                isMobile: window.innerWidth < 600
            });
        }, false);
    }

    adaptCategory(category) {
        if (category === 'usate') {
            return 'Usato';
        }
        if (category === 'km_0') {
            return 'KM 0'
        }
        if (category === 'aziendali') {
            return 'Aziendale'
        }
        return 'Categoria non specificata'
    }

    adaptKm(km) {
        if (km.length <= 3) {
            return km
        }
        return km.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    closeAnticipoModal() {
        this.setState({ showAnticipoModal: false })
    }

    openAnticipoModal() {
        this.setState({ showAnticipoModal: true })
    }

    closeAcquistoModal() {
        this.setState({ showAcquistoModal: false })
    }

    openAcquistoModal() {
        this.setState({ showAcquistoModal: true })
    }


    render() {
        const showAcquistaOnlineButton = this.props.car.acquista_online;
        let acquistaOnlineButton;
        if (showAcquistaOnlineButton) {
            acquistaOnlineButton = 
            <div className="text-center" style={{ marginTop: "10px", marginBottom: "20px", zIndex: "1000" }}>
                <Button style={{ width: "280px", backgroundColor: darkGray }} variant="secondary" onClick={() => this.openAcquistoModal()}><strong>ACQUISTO ONLINE</strong></Button>
            </div>
        } else {
            acquistaOnlineButton = <div style={{marginBottom: "20px"}}></div>
        }
        const stampaButton =  this.props.car.venduta ? null : <div className="text-center" style={{ marginTop: "10px", zIndex: "1000" }}>
        <Button style={{ width: "280px", backgroundColor: darkGray }} variant="secondary" as={Link} to={"/auto/stampa/" + this.props.car.id}><strong>STAMPA SCHEDA AUTO</strong></Button>
    </div>
        if (!this.state.isMobile) {
            return (
                <Sticky>
                    {({ style, isSticky }) => (
                        <Container style={{ ...style, border: "1px solid #ccc", marginTop: isSticky ? "130px" : "0px", backgroundColor: middleGray }}>
                            <Modal show={this.state.showAnticipoModal} onHide={() => this.closeAnticipoModal()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Prenota veicolo</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Chiamaci al +39 3336487387 o invia una mail a <a href={"mailto: luca.cufone@autolg.it?subject=Prenotazione " + this.props.car.titolo}>luca.cufone@autolg.it</a>.
                                    Ti indicheremo come potrai bloccare questa auto versandoci un piccolo acconto.
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.closeAnticipoModal()}>
                                        CHIUDI
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={this.state.showAcquistoModal} onHide={() => this.closeAcquistoModal()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Acquisto Online</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {text1}
                                    <br/>
                                    <br/>
                                    {text2}
                                    <br/>
                                    <br/>
                                    {text3}
                                    <br/>
                                    <br/>
                                    {text4}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.closeAcquistoModal()}>
                                        CHIUDI
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <SummaryCarInfo car={this.props.car} />
                            <div className="text-center" style={{ marginTop: "10px", zIndex: "1000" }}>
                                <Button style={{ width: "280px", backgroundColor: darkGray }} as={Link} to="/finanziamento" variant="secondary"><strong>SIMULA UN FINANZIAMENTO</strong></Button>
                            </div>
                            {/* <div className="text-center" style={{ marginTop: "10px", zIndex: "1000" }}>
                                <Button style={{ width: "280px", backgroundColor: darkGray }} variant="secondary" as={Link} to={"/auto/stampa/" + this.props.car.id}><strong>STAMPA SCHEDA AUTO</strong></Button>
                            </div> */}
                            {stampaButton}
                            <div className="text-center" style={{ marginTop: "10px", zIndex: "1000" }}>
                                <Button style={{ width: "280px", color: "white", backgroundColor: darkGray }} variant="secondary" onClick={() => this.openAnticipoModal()}><strong>PRENOTA VEICOLO</strong></Button>
                            </div>
                            {/* <div className="text-center" style={{ marginTop: "10px", marginBottom: "20px", zIndex: "1000" }}>
                                <Button style={{ width: "280px", backgroundColor: darkGray }} variant="secondary" onClick={() => this.openAcquistoModal()}><strong>ACQUISTO ONLINE</strong></Button>
                            </div> */}
                            {acquistaOnlineButton}
                            {/* <div style={{marginBottom: "20px"}}></div> */}
                        </Container>
                    )}
                </Sticky>
            )
        }
        else {
            return (
                <Container style={{ border: "1px solid #ccc" }}>
                            <Modal show={this.state.showAnticipoModal} onHide={() => this.closeAnticipoModal()}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Prenota veicolo</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Chiamaci al +39 3336487387 o invia una mail a <a href={"mailto: luca.cufone@autolg.it?subject=Prenotazione " + this.props.car.titolo}>luca.cufone@autolg.it</a>.
                                    Ti indicheremo come potrai bloccare questa auto versandoci un piccolo acconto.
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.closeAnticipoModal()}>
                                        CHIUDI
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                    <Modal show={this.state.showAcquistoModal} onHide={() => this.closeAcquistoModal()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Acquisto Online</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {text1} 
                            <br/>
                            <br/>
                            {text2}
                            <br/>
                            <br/>
                            {text3}
                            <br/>
                            <br/>
                            {text4}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.closeAcquistoModal()}>
                                CHIUDI
                                    </Button>
                        </Modal.Footer>
                    </Modal>

                    <SummaryCarInfo car={this.props.car} />

                    {/* <div className="text-center" style={{ marginTop: "10px", zIndex: "1000" }}>
                        <Button style={{ width: "280px", color: "white", backgroundColor: darkGray }} as={Link} to="/contatti" variant="dark"><strong>RICHIEDI PIU' INFORMAZIONI</strong></Button>
                    </div> */}
                    <div className="text-center" style={{ marginTop: "10px", zIndex: "1000" }}>
                        <Button style={{ width: "280px", color: "white", backgroundColor: darkGray }} as={Link} to="/finanziamento" variant="dark"><strong>SIMULA UN FINANZIAMENTO</strong></Button>
                    </div>
                    {/* <div className="text-center" style={{ marginTop: "10px", zIndex: "1000" }}>
                        <Button style={{ width: "280px", color: "white", backgroundColor: darkGray }} variant="dark" as={Link} to={"/auto/stampa/" + this.props.car.id}><strong>STAMPA SCHEDA AUTO</strong></Button>
                    </div> */}
                    {stampaButton}
                    <div className="text-center" style={{ marginTop: "10px", zIndex: "1000" }}>
                        <Button style={{ width: "280px", color: "white", backgroundColor: darkGray }} variant="dark" onClick={() => this.openAnticipoModal()}><strong>PRENOTA VEICOLO</strong></Button>
                    </div>
                    {/* <div className="text-center" style={{ marginTop: "10px", marginBottom: "20px", zIndex: "1000" }}>
                        <Button style={{ width: "280px", backgroundColor: darkGray }} variant="secondary" onClick={() => this.openAcquistoModal()}><strong>ACQUISTO ONLINE</strong></Button>
                    </div> */}
                    {/* <div style={{marginBottom: "20px"}}></div> */}
                    {acquistaOnlineButton}
                </Container>
            )
        }
    }
}

export default Summary;