import React from 'react'
import { Container, Row } from 'react-bootstrap'
import ReviewCard from './ReviewCard'
import { lightGray, darkGray } from '../../conf'

const claudio = 
{
    title: "Claudio da AutoScout24",
    text: 'Venditore, serio, affidabile e puntuale. In 5 giorni ha acquisito la mia vecchia auto e mi ha venduto un ottima citycar con meno di 20.000 km ancora in garanzia della casa madre. Ho risparmiato 5.000 euro portando a casa un auto pari a nuovo full optional. Tornerò sicuramente a comprare la prossima auto. Un piccolo rivenditore con grandi occasioni e grande professionalità.'
}
const anna = 
{
    title: "Anna da AutoScout24",
    text: 'Luca il titolare della concessionaria è una persona seria e trasparente. Sin da subito si è messo a disposizione per qualsiasi cosa e ci ha fatto visionare la macchina in ogni suo dettaglio. Pone il cliente al centro dell\'attenzione e punta tutto a stabilire un relazione di lungo termine che, come suo cliente, non posso che piacevolmente confermare. Consiglio a tutti Auto LG'
}
const giovanni = 
{
    title: "Giovanni da Google",
    text: "Ho acquistato oggi da AutoLG una Audi A4, vendendo contemporaneamente la mia precedente vettura. In entrambi i casi i miei desideri ed esigenze sono stati capiti e soddisfatti pienamente. La professionalità, competenza  e cortesia del Sig. Luca, titolare, sono state, ancora una volta, confermate. Rivolgetevi a lui con fiducia per ogni necessità o consulenza."
}
const gabriele = {
    title: "Gabriele da AutoScout24",
    text: "Ha capito subito le mie esigenze ed è riuscito a risolvere alcuni problemi che molti altri non avrebbero nemmeno preso in considerazione. La mia assicurazione ha fatto casini e Luca si è fatto in 4 per aiutarmi . L'auto è perfetta e finalmente me la godo. Dopo tanto girare finalmente ho trovato il mio concessionario di fiducia. Grazie Luca!"
}
const simone = {
    title: "Simone da Google",
    text: "Abbiamo acquistato una Toyota RAV4 Hybrid vendendo una Audi Q3 usata, Luca dopo averci fatto una valutazione molto buona dell'usato, ci ha fatto anche un buon prezzo sul nuovo di conseguenza abbiamo deciso di procedere all'acquisto. Luca ci ha consegnato l'auto direttamente in ufficio, praticamente una consegna a domicilio! Servizio IMPECCABILE!"
}

const chiara = {
    title: "Chiara da AutoScout24",
    text: "Ho avuto modo di conoscere il titolare di AUTO LG qualche settimana fa, durante le operazioni di vendita della mia auto. Ho trovato una persona competente, professionale e disponibile come poche altre al giorno d'oggi! Mi sono sentita molto seguita, sia per quanto riguarda la fase di preparazione della documentazione che per l'appuntamento all'ACI."
}

const annalisa = {
    title: "Annalisa da Google",
    text: "Dovendo cambiare auto mi sono rivolta a Luca che si è dimostrato gentile e sempre disponibile, mi ha rivolto in breve tempo una proposta eccellente in linea con le mie esigenze, acquistando il mio vecchio veicolo usato ed occupandosi di ogni procedura nei minimi dettagli. L'auto è perfetta per me ed è stata ottima l'esperienza con questo venditore, che consiglio e ringrazio"
}

const riccardo = {
    title: "Riccardo da Google",
    text: "Davvero soddisfatto dell'acquisto della mia Seat Leon! Luca si è dimostrato affidabile e professionale e mi ha seguito bene tenendomi aggiornato per quanto riguarda i vari step dall'acquisto alla consegna. Un ringraziamento anche al padre Carmine anch'esso parte dello staff. Complimenti e bravi! Ne terrò da conto per un altro acquisto futuro"
}

const marco = {
    title: "Marco da Google",
    text: "Ho venduto la mia auto affidandomi a Luca di cui segnalo la grande professionalità ed affidabilità. Dopo un primo contatto telefonico è seguito un incontro presso la sua sede di verifica delle condizioni dell'auto e di check sul prezzo di vendita. Si è occupato lui di tutte le pratiche rendendo l'operazione estremamente facile e veloce. Consigliatissimo"
}

const francesco = {
    title: "Francesco da Google",
    text: "Ho acquistato una vettura da Luca ad inizio Novembre, ho aspettato a recensire per fare un po’di km con la vettura e devo dire che se inizialmente ero soddisfatto della gentilezza e professionalità di Luca e di suo padre ora, dopo circa 4.500 km posso dirmi soddisfatto anche della vettura.macchina senza alcun problema e perfettamente conforme a quanto proposto."
}

const orietta = {
    title: "Orietta da AutoScout24",
    text: "Difficile di questi tempi trovare un'auto usata in ottime condizioni ad un giusto prezzo. Difficile trovare persone serie che ti seguono con professionalità, calma e pazienza. O sono stata molto fortunata o ci sono ancora piccole imprese familiari che lavorano con serietá e attenzione alle persone. Quei posti dove ci si guada negli occhi e ci si stringe la mano."
}

const francesco2 = {
    title: "Francesco da AutoScout24",
    text: "E' la seconda auto che compro da Luca ed e' stata una piacevole riconferma. Luca si e' dimostrato nuovamente molto professionale e mi ha guidato passo per passo alla vendita del mio usato e acquisto della nuova auto. E' riuscito a procurarmi esattamente l'auto che cercavo. Consiglieri AutoLG a chiunque perche' offre professionalita', sicurezza ed affidabilita'."
}

const martino = {
    title: "Martino da AutoScout24",
    text: "Abbiamo acquistato e ritirato in poco più di due ore la nostra nuova auto da AUTO LG MONZA. La procedura si è svolta in modo semplice, veloce e sicuro. Abbiamo potuto visionare e provare l’auto già precedentemente descritta al telefono, procedendo con pagamento, pratiche auto ed assicurazione in poco tempo. Lo consiglierei sicuramente."
}

const francesca = {
    title: "Francesca da AutoScout24",
    text: "Ho comprato la mia nuova auto da AUTO LG. Luca è stato molto preciso nel fornirci informazioni che riguardavano l'acquisto e la funzionalità della macchina. Ha risposto con competenza e professionalità ad ogni mia domanda o dubbio. Risceglierei senza dubbio AUTO LG e mi avvarrò ancora della sua professionalità se dovessi avere bisogno in futuro. Consigliatissimo!"
}

const vincenzo = {
    title: "Vincenzo da Google",
    text: "Cosa posso dire....... più stelle non ne posso mettere. Luca e papà sono , per me , due professionisti nelle vendite di auto. Voglio sottolineare che , oltre a essere dei professionisti sono anche bravi a farti sentire in buone mani. SE UN'AUTO DOVETE ACQUISTARE DA LUCA E PAPÀ DOVETE ANDARE. Oltre a venditori ora sono anche amici. GRAZIE"
}

const alberto = {
    title: "Alberto da Google",
    text: "Ho venduto la mia auto ad Auto LG ed è stata una esperienza da 5 stelle. Ho trovato chiarezza sin dal primo contatto telefonico ed ho apprezzato la trasparenza e la precisione di Luca, il titolare. Questa precisione mi ha permesso di arrivare alla vendita senza perdite di tempo o intoppi e soprattutto senza brutte sorprese. Consiglio davvero a tutti."
}

const carmine = {
    title: "Carmine da Google",
    text: "Non posso che dare il massimo delle stelle a Luca per l’ottimo lavoro svolto per far si che potessi comprare l’auto che inseguivo da tanto tempo. Luca è sempre stato disponibile, chiaro e trasparente durante tutte le fasi d’acquisto, supportandomi in tutti gli step necessari. Consiglio a tutti  di fare un salto sul loro sito web/ salone in quanto la diversità di vetture è notevole."
}


const reviewsSet = [claudio, anna, giovanni, gabriele, simone, chiara, annalisa, riccardo, marco, francesco, orietta, francesco2, martino, francesca, vincenzo, alberto, carmine]
const shuffled = reviewsSet.sort(() => 0.5 - Math.random());
const reviewsToShow = shuffled.slice(0,3)

const reviews = () => {
    return (
        <div style={{ width: "100%", paddingTop: "30px", paddingBottom: "30px", backgroundColor: lightGray }}>
            <Container style={{ textAlign: "center" }}>
                <div style={{ backgroundColor: darkGray, color: "white", margin: "auto" }}>
                    <h3>LE RECENSIONI DEI NOSTRI CLIENTI</h3>
                </div>
                {/* <div style={{color: "white", backgroundColor: darkGray, border: "1px solid #B3B3B3" }}>
                LEGGI TUTTE LE NOSTRE RECENSIONI SU <a href="https://www.autoscout24.it/concessionari/auto-lg-di-cufone-luca/recensioni" rel="noopener noreferrer" target="_blank" style={{ color: "#585858" }}>AutoScout24</a> e <a href="https://www.google.it/search?source=hp&ei=KdebX6KgCciMlwSMrqOYDA&q=auto%20lg&oq=auto+lg&gs_lcp=CgZwc3ktYWIQAzICCAAyAggAMgIIADICCAAyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAo6CAgAELEDEIMBOgUIABCxA1DW-JYDWNb_lgNgroGXA2gAcAB4AIAB8geIAe4NkgEHMi41LjctMZgBAKABAaoBB2d3cy13aXqwAQA&sclient=psy-ab&ved=2ahUKEwjJtteAlNzsAhVGxYUKHf6-DqcQvS4wAHoECAsQKw&uact=5&npsic=0&rflfq=1&rlha=0&rllag=45580413,9264601,3065&tbm=lcl&rldimm=2316154086190096256&lqi=CgdhdXRvIGxnWhIKB2F1dG8gbGciB2F1dG8gbGc&phdesc=S5i-3BbA7xI&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10&rlst=f#lrd=0x4786bf1a7180aa0d:0x2024a1deb06e1f80,1,,,&rlfi=hd:;si:2316154086190096256,l,CgdhdXRvIGxnWhIKB2F1dG8gbGciB2F1dG8gbGc,y,S5i-3BbA7xI;mv:[[45.59068997731902,9.301542190673427],[45.590330022680966,9.301027809326571]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10" rel="noopener noreferrer" target="_blank" style={{ color: "#585858" }}>Google</a>
                </div> */}
                <h6>LEGGI TUTTE LE NOSTRE RECENSIONI SU <a href="https://www.autoscout24.it/concessionari/auto-lg-di-cufone-luca/recensioni" rel="noopener noreferrer" target="_blank" style={{ color: "#585858" }}>AUTOSCOUT24</a> E <a href="https://www.google.it/search?source=hp&ei=KdebX6KgCciMlwSMrqOYDA&q=auto%20lg&oq=auto+lg&gs_lcp=CgZwc3ktYWIQAzICCAAyAggAMgIIADICCAAyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAo6CAgAELEDEIMBOgUIABCxA1DW-JYDWNb_lgNgroGXA2gAcAB4AIAB8geIAe4NkgEHMi41LjctMZgBAKABAaoBB2d3cy13aXqwAQA&sclient=psy-ab&ved=2ahUKEwjJtteAlNzsAhVGxYUKHf6-DqcQvS4wAHoECAsQKw&uact=5&npsic=0&rflfq=1&rlha=0&rllag=45580413,9264601,3065&tbm=lcl&rldimm=2316154086190096256&lqi=CgdhdXRvIGxnWhIKB2F1dG8gbGciB2F1dG8gbGc&phdesc=S5i-3BbA7xI&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10&rlst=f#lrd=0x4786bf1a7180aa0d:0x2024a1deb06e1f80,1,,,&rlfi=hd:;si:2316154086190096256,l,CgdhdXRvIGxnWhIKB2F1dG8gbGciB2F1dG8gbGc,y,S5i-3BbA7xI;mv:[[45.59068997731902,9.301542190673427],[45.590330022680966,9.301027809326571]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10" rel="noopener noreferrer" target="_blank" style={{ color: "#585858" }}>GOOGLE</a>
                </h6>
            </Container>
            <Container>
                <Row style={{ marginTop: "20px" }}>
                    <ReviewCard title={reviewsToShow[0].title} text={reviewsToShow[0].text} />
                    <ReviewCard title={reviewsToShow[1].title} text={reviewsToShow[1].text} className="review_card" />
                    <ReviewCard title={reviewsToShow[2].title} text={reviewsToShow[2].text} className="review_card" />
                </Row>
            </Container>
        </div>
    );
}

export default reviews;