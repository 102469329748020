import React from 'react'
import video from '../../assets/video_compriamo.mov'
import { Container } from 'react-bootstrap'
import {lightGray} from '../../conf'

const videoCompriamo = () => {
    return (
        <div style={{backgroundColor: lightGray, textAlign: "center"}}>
        <Container>
            {/* <Row> */}
                {/* <Col style={{margin: "auto"}}> */}
                    <video id="background-video" loop autoPlay playsInline style={{ width: "70%", height: "40%" }}>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                {/* </Col> */}
            {/* </Row> */}
        </Container>
        </div>
    );
}

export default videoCompriamo;