import React from 'react'
import video from '../../assets/video.mp4'
import { Container, Row } from 'react-bootstrap'
import {middleGray} from '../../conf'

const videoContainer = () => {
    return (
        <div style={{backgroundColor: middleGray}}>
        <Container>
            <Row>
                {/* <Col style={{margin: "auto"}}> */}
                    <video id="background-video" loop autoPlay muted playsInline style={{ width: "100%", height: "100%" }}>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                {/* </Col> */}
            </Row>
        </Container>
        </div>
    );
}

export default videoContainer;