import React from 'react'
import { Image, Col } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link'

const serviceIcon = (props) => {


    return (
        <Col as={Link} className="no_underline" to={"/servizi#" + props.anchor} xs={6} sm={4} md={4} lg={4} xl={2} style={{ textAlign: "center", color: "#212529" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexFlow: "column", paddingBottom: "15px" }}>
                <div style={{ marginBottom: "10px" }}>
                    <Image src={props.icon} rounded fluid style={{ height: "110px", marginTop: "30px" }} />
                </div>
                <strong>{props.title}</strong>
            </div>
        </Col>
    );
}

export default serviceIcon;