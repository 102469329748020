import React from 'react'
import { FacebookShareButton, FacebookIcon, FacebookMessengerShareButton, FacebookMessengerIcon, WhatsappIcon, WhatsappShareButton, TelegramIcon, TelegramShareButton, EmailShareButton, EmailIcon } from 'react-share'

const share = () => {
    return (
        <div style={{float: "right"}}>
            {/* <h5 style={{ marginBottom: "20px", marginTop: "8px", float: "left" }}>
                Condividi
            </h5> */}
            {/* <div style={{float: "right"}}> */}
                <WhatsappShareButton url={window.location.href} style={{marginRight: "10px", marginLeft: "30px"}}>
                    <WhatsappIcon size={35} round />
                </WhatsappShareButton>
                <FacebookShareButton url={window.location.href} style={{marginRight: "10px"}}>
                    <FacebookIcon size={35} round />
                </FacebookShareButton>
                <FacebookMessengerShareButton url={window.location.href} style={{marginRight: "10px"}}>
                    <FacebookMessengerIcon size={35} round />
                </FacebookMessengerShareButton>
                <TelegramShareButton url={window.location.href} style={{marginRight: "10px"}}>
                    <TelegramIcon size={35} round />
                </TelegramShareButton>
                <EmailShareButton url={window.location.href} body={"Hey, guarda questo annuncio di auto: "}>
                    <EmailIcon size={35} round />
                </EmailShareButton>
            {/* </div> */}
        </div>
    )
}

export default share;