import React from 'react'
import MyMap from './GoogleMap'
import InfoContainer from './InfoContainer'
import {Jumbotron, Container, Col, Row, Figure} from 'react-bootstrap'
import contattiImg from '../../assets/contatti_new.jpg'
import { Link } from 'react-router-dom'
import {middleGray, darkGray} from '../../conf'

const contactPage = () => {
    return (
        <div>
            <MyMap />
            <InfoContainer />
            <Jumbotron style={{marginBottom: "0px", backgroundColor: middleGray}}>
                <Container>
                    <Row>
                        <Col sm={7}>
                            <h3 style={{ paddingLeft: "10px", backgroundColor: darkGray, color: "white", textTransform: "uppercase", textAlign: "center" }}>Chi siamo</h3>
                            Auto LG, nata nel 2007,  offre ai propri clienti un'ampia gamma di vetture nuove, usate, 
                            chilometri zero, aziendali e mezzi commerciali in grado di soddisfare le richieste più 
                            esigenti in termini di allestimento e motorizzazione. Su appuntamento potremo mostrarvi 
                            tutte le nostre auto e non solo; vi offriremo consulenza su tutti le possibilità legate 
                            alla compravendita e e vi guideremo alla scelta di una macchina che rispecchi le vostre esigenze.
                            <br/>
                            <br/>

                            Auto LG, da quasi vent'anni, offre ai propri clienti molti servizi: potremo consigliarvi 
                            il finanziamento o leasing migliore, guidarvi nella scelta tra noleggio o full leasing, 
                            gestire il vostro parco auto aziendale, eseguire pratiche auto quali il passaggio di proprietà, 
                            prenotare i collaudi, le demolizioni o ancora disporre dei nostri tecnici d’officina, 
                            carrozzeria ed elettrauto. Per un elenco completo delle possibilità consigliamo di navigare 
                            la pagina <Link to="/servizi">servizi</Link> del nostro sito.
                            <br/>
                            <br/>
                            Auto LG è anche attivo sui social network più importanti, 
                            come <a href="https://www.instagram.com/auto_lg/" target="_blank" rel="noopener noreferrer">Instagram</a> 
                            , <a href="https://www.facebook.com/AUTOLG" target="_blank" rel="noopener noreferrer">Facebook</a>
                            , <a href="https://www.youtube.com/channel/UC2Hg0j_H8HA_snC3GrjfFtg" target="_blank" rel="noopener noreferrer">Youtube</a>,
                            e <a href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0TE-vyjWyqMwyYLRSNagwMbcwS0ozTDQ3tDBITDRIsTKoMDIwMkk0TElNMjBLNUyzMPBiTywtyVfISQcANq4SAA&q=auto+lg&rlz=1C1CHBF_itIT1011IT1011&oq=&aqs=chrome.0.46i39i175i199i362j35i39i362l4j46i39i175i199i362j35i39i362l2.1056250813j0j15&sourceid=chrome&ie=UTF-8#lpc=lpc" target="_blank" rel="noopener noreferrer">Google</a>, 
                            dove potrete rimanere aggiornati sulle promozioni, iniziative ed eventi.
                            <br/>
                            Inoltre Auto LG dispone di una pagina personale sul sito di inserzione di auto più famoso 
                            in Europa, <a href="https://www.autoscout24.it/concessionari/auto-lg-di-cufone-luca?srcl=1" target="_blank" rel="noopener noreferrer">Autoscout24</a>.
                            {/* , e di uno shop online su <a href="https://impresapiu.subito.it/shops/12889-auto-lg?xtfrom=directory" target="_blank" rel="noopener noreferrer">Subito.it</a>. */}
                            <br/>
                            <br/>
                            Per qualsiasi informazione, non esitate a contattarci, Vi aspettiamo!

                        </Col>
                        <Col sm={5}>
                            <Figure style={{marginTop: "80px"}}>
                                <Figure.Image
                                    src={contattiImg}
                                />
                            </Figure>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </div>
    )
}

export default contactPage;