import React from 'react'
import { Jumbotron, Container, Col, Row, Figure } from 'react-bootstrap'
import { darkGray } from '../../conf'

const serviceCard = (props) => {
    if (props.textLayout === 'left') {
        return (
            <Jumbotron  style={{ backgroundColor: props.backColor, marginBottom: "0px"}}>
                <Container id={props.anchor} className="anchor">
                    <Row>
                        <Col xs="12" lg="7">
                            <h3 style={{ paddingLeft: "10px", backgroundColor: darkGray, color: "white", textTransform: "uppercase", textAlign: "center" }}>{props.title}</h3>
                            {props.text}
                        </Col>
                        <Col xs="12" lg="5">
                            <Figure>
                                <Figure.Image
                                    src={props.img}
                                />
                            </Figure>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        )
    }
    return (
        <Jumbotron style={{ backgroundColor: props.backColor, marginBottom: "0px" }}>
            <Container id={props.anchor} className="anchor">
                <Row>
                    <Col xs="12" lg="5">
                        <Figure>
                            <Figure.Image
                                src={props.img}
                            />
                        </Figure>
                    </Col>
                    <Col xs="12" lg="7">
                        <h3 style={{ paddingLeft: "10px", backgroundColor: darkGray, color: "white", textTransform: "uppercase", textAlign: "center" }}>{props.title}</h3>
                        {props.text}
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    );
}

export default serviceCard;

