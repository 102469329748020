import React from 'react'
import Popover from 'react-bootstrap/Popover'

const sellTypePopover = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Come vuoi vendere la tua auto?</Popover.Title>
        <Popover.Content>
            <h6>Vendita diretta</h6>
            Con la vendita diretta AutoLG effettuera' una valutazione della tua vettura e qualora tu la accettassi, pagherà immediatamente il 
            prezzo stabilito e l'auto verrà direttamente venduta a noi. 
            <br/>
            <br/>
            <h6>Conto vendita</h6>
            Con il servizio conto vendita fai tu il prezzo! AutoLG ti offrirà consulenza per stabilire un prezzo competitivo e si occuperà della 
            vendita al posto tuo: dalla preparazione di un book fotografico all'inserimento del vostro veicolo sui maggiori gestionali d’auto sul web;
             dalla custodia del vostro veicolo presso la propria sede al pagamento e produzione della documentazione certificata.
      </Popover.Content>
    </Popover>
);

export default sellTypePopover;