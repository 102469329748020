import React from 'react'
import {strapiUrl} from '../../conf'
import EquipmentCard from './EquipmentCard'

class PrintableCarPage extends React.Component {


    adaptCategory(category) {
        if (category === 'usate') {
            return 'Usato';
        }
        if (category === 'km_0') {
            return 'KM 0'
        }
        if (category === 'aziendali') {
            return 'Aziendale'
        }
        return 'Categoria non specificata'
    }

    adaptKm(km) {
        if (km.length <= 3) {
            return km
        }
        return km.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    prettifyDescription(description) {
        let descriptionWithoutSpecialChars = description.replace('\\', '');
        while (descriptionWithoutSpecialChars.includes('\\')) {
            descriptionWithoutSpecialChars = descriptionWithoutSpecialChars.replace('\\', '');
        }

        const splittedDescription = descriptionWithoutSpecialChars.split('\n');
        const newDescription = splittedDescription.map((line, index) => <p key={index}>{line}</p>);
        return newDescription;
    }

    constructor(props) {
        super(props);

        this.state = {
            car: null,
            isFetching: true
        };
    }

    async componentDidMount() {
        this.setState({ isFetching: true })
        fetch(strapiUrl + '/cars/' + this.props.match.params.carId)
            .then(response => response.json())
            .then(data => {
                this.setState({ car: data, isFetching: false })
                window.print()
                window.history.back();
            }
            );
    }

    render() {
        if (this.state.isFetching) {
            return (
                <div />
            )
        }
        const equipments = [
            {
                title: "Comfort",
                eq: this.state.car.comfort
            },
            {
                title: "Extra",
                eq: this.state.car.extra
            },
            {
                title: "Sicurezza",
                eq: this.state.car.sicurezza
            },
            {
                title: "Intrattenimento",
                eq: this.state.car.intrattenimento
            }
        ]
        const cards = equipments.map((eq, index) => <EquipmentCard key={index} equipment={equipments[index]}/>)
        return (
            <div>
                {/* <Gallery car={this.state.car} /> */}
                <div style={{ float: "left" }}>
                    <h1 style={{ marginBottom: "20px", color: "#363B3D" }}>
                        {this.state.car.titolo}
                    </h1>
                </div>
                <div style={{ float: "right", marginBottom: "12px" }}>
                    <h1 style={{ color: "#363B3D" }}>€ {this.state.car.prezzo}</h1>
                </div>
                <br/>
                <br/>
                <img src={strapiUrl + this.state.car.cover.url}  alt="car" width="auto" height="600"></img>
                <hr></hr>
                <i className="fas fa-tags"></i>
                <span style={{ fontWeight: "600", marginLeft: "10px" }}>Categoria</span>
                <span style={{ float: "right", display: "inline" }}>{this.adaptCategory(this.state.car.categoria)}</span>
                <hr></hr>
                <i className="fas fa-gas-pump" style={{ float: "left" }}></i>
                <span style={{ fontWeight: "600", marginLeft: "10px" }}>Alimentazione</span>
                <span style={{ float: "right", display: "inline" }}>{this.state.car.alimentazione}</span>
                <hr></hr>
                <i className="fas fa-cog"></i>
                <span style={{ fontWeight: "600", marginLeft: "10px" }}>Cilindrata</span>
                <span style={{ float: "right", display: "inline" }}>{this.state.car.cilindrata} cm³</span>
                <hr></hr>
                <i className="fas fa-horse"></i>
                <span style={{ fontWeight: "600", marginLeft: "10px" }}>Potenza</span>
                <span style={{ float: "right", display: "inline" }}>{this.state.car.potenza}</span>
                <hr></hr>
                <i className="fas fa-caret-right"></i>
                <span style={{ fontWeight: "600", marginLeft: "10px" }}>Cambio</span>
                <span style={{ float: "right", display: "inline" }}>{this.state.car.cambio}</span>
                <hr></hr>
                <i className="far fa-calendar"></i>
                <span style={{ fontWeight: "600", marginLeft: "10px" }}>Anno</span>
                <span style={{ float: "right", display: "inline" }}>{this.state.car.anno}</span>
                <hr></hr>
                <i className="fas fa-road"></i>
                <span style={{ fontWeight: "600", marginLeft: "10px" }}>Km</span>
                <span style={{ float: "right", display: "inline" }}>{this.adaptKm(this.state.car.km)} km</span>
                <hr></hr>
                <h3 style={{ color: "#363B3D" }}>Descrizione</h3>
                {this.prettifyDescription(this.state.car.descrizione)}
                {cards}
            </div>
        )
    }
}

export default PrintableCarPage;