import React from 'react'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import Image from 'react-bootstrap/Image';
import { SocialIcon } from 'react-social-icons';
import autoscoutLogo from '../assets/autoscout.png';
import {darkGray} from '../conf'

const footer = () => {
    return (
        <div style={{bottom: "0", marginTop: "-7px" }}>
            <CardGroup style={{backgroundColor: darkGray}}>
                <Card text="white" style={{ border: "none", backgroundColor: darkGray, marginBottom: "0px" }}>
                    <Card.Body style={{ fontSize: "12px" }}>
                    <div className="footer_first_card_body">
                        <Card.Title><u>CONTATTI</u></Card.Title>
                        <div style={{color: "rgb(228, 228, 231)"}}>
                        <div style={{ float: "left"}}>
                            <span style={{ color: "white", float: "left", paddingTop: "15%" }}>
                                <i className="fas fa-map-marker-alt" />
                            </span>
                            <p style={{ marginLeft: "20px", color: "E4E4E7" }}>
                            <a style={{ textDecoration: "none", color: "inherit" }} href="https://goo.gl/maps/8FM787ft3ZpViqyZ9" target="_blank" rel="noopener noreferrer">Via Fogazzaro 4/6,</a><br />
                            <a style={{ textDecoration: "none", color: "inherit" }} href="https://goo.gl/maps/8FM787ft3ZpViqyZ9" target="_blank" rel="noopener noreferrer">Monza (MB) </a><br />
                            <a style={{ textDecoration: "none", color: "inherit" }} href="https://goo.gl/maps/8FM787ft3ZpViqyZ9" target="_blank" rel="noopener noreferrer">20900 ITALIA</a>
                                </p>
                        </div>
                        <div style={{ marginLeft: "170px" }}>
                            <span style={{ color: "white", float: "left" }}>
                                <i className="fas fa-phone" />
                            </span>
                            <p style={{ marginLeft: "20px" }}>
                                333 6487387
                                039 9417693
                            </p>
                            <span style={{ color: "white", float: "left" }}>
                                <i className="fas fa-envelope" />
                            </span>
                            <p style={{ marginLeft: "20px" }}>  
                                <a style={{color: "rgb(228, 228, 231)"}} href="mailto: luca.cufone@autolg.it">luca.cufone@autolg.it</a> <br />
                            </p>
                        </div>
                    
                        </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card text="white" style={{ border: "none", backgroundColor: darkGray, marginBottom: "0px" }}>
                    <Card.Body style={{ fontSize: "12px", textAlign: "center" }}>
                        <Card.Title><u>STAY TUNED</u></Card.Title>
                        <div style={{color: "rgb(228, 228, 231)"}}>
                            AutoLG è presente e attivo sui maggiori Social e sulle più importanti vetrine di auto nel web! <strong>Seguici per rimanere sempre aggiornato</strong>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "20px" }}>
                            <a href="https://www.autoscout24.it/concessionari/auto-lg-di-cufone-luca?srcl=1" target="_blank" rel="noopener noreferrer"><Image src={autoscoutLogo} roundedCircle style={{ width: "40px", marginRight: "10px" }} /></a>
                            {/* <a href="https://impresapiu.subito.it/shops/12889-auto-lg?xtfrom=directory" target="_blank" rel="noopener noreferrer"><Image src={subitoLogo} roundedCircle style={{ width: "40px", marginRight: "10px" }} /></a> */}
                            <SocialIcon style={{ marginRight: "10px", width: "40px", height: "40px" }} url="https://wa.me/393336487387" network="whatsapp" fgColor={darkGray} bgColor="white" target="_blank" rel="noopener noreferrer" />
                            <SocialIcon style={{ marginRight: "10px", width: "40px", height: "40px" }} url="https://www.instagram.com/auto_lg/" fgColor={darkGray} bgColor="white" target="_blank" rel="noopener noreferrer" />
                            <SocialIcon style={{ marginRight: "10px", width: "40px", height: "40px" }} url="https://facebook.com/AUTOLG" fgColor={darkGray} bgColor="white" target="_blank" rel="noopener noreferrer" />
                            <SocialIcon style={{ width: "40px", height: "40px" }} url="https://www.youtube.com/channel/UC2Hg0j_H8HA_snC3GrjfFtg" fgColor={darkGray} bgColor="white" target="_blank" rel="noopener noreferrer" />
                        </div>
                    </Card.Body>
                </Card>
                <Card text="white" style={{ border: "none", backgroundColor: darkGray, marginBottom: "0px" }}>
                    <Card.Body style={{ fontSize: "12px", textAlign: "right" }}>
                        <div className="footer_last_card_body">
                            <Card.Title><u>DATI FISCALI</u></Card.Title>
                            <div style={{color: "rgb(228, 228, 231)"}}>
                            <strong>P. IVA</strong> 13419840965 - <strong>C.F.</strong> 13419840965  <br />
                            <strong>CODICE DESTINATARIO</strong> M5UXCR1 <br />
                            <strong>SEDE LEGALE</strong> Via Fogazzaro 4, Monza, 20900
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
    )
}

export default footer;