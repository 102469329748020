import React, { useState } from 'react';
import { Button, Modal, Form, OverlayTrigger } from 'react-bootstrap'
import Row from './Row'
import TextInputField from './TextInputField'
import privacyPopover from './PrivacyPopover';
import { darkGray, lightGray } from '../../conf'
import sellTypePopover from './SellTypeDescription';
import VideoCompriamo from './VideoCompriamo'

const WeBuyPage = () => {
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === true) {
            window.emailjs.send(
                'gmail', 'acquistiamousato',
                {
                    name: form.name.value,
                    mail: form.mail.value,
                    phone: form.phone.value,
                    marca: form.marca.value,
                    allestimento: form.allestimento.value,
                    cilindrata: form.cilindrata.value,
                    alimentazione: form.alimentazione.value,
                    km: form.km.value,
                    anno: form.anno.value,
                    targa: form.targa.value,
                    cambio: form.cambio.value,
                    tagliando: form.tagliando.value,
                    colore: form.colore.value,
                    proprietari: form.proprietari.value,
                    info: form.info.value,
                    tipovendita: form.tipovendita.value,
                    classe: form.classe.value,
                    richiesta: form.richiesta.value
                }
            ).then(res => {
                console.log('Email successfully sent!')
                handleShow()

            })
            .catch(err => {
                console.error('Oh well, you failed. Here some thoughts on the error that occured:', err)
                handleShowError()
            })
        }
    };

    return (
        <div style={{ marginLeft: "80px", marginRight: "80px", marginTop: "150px", marginBottom: "20px" }}>
            <VideoCompriamo />
            {/* <p>
                Vuoi vendere la tua auto velocemente e senza rischi?
            </p> */}
            <h4 style={{ paddingLeft: "10px", backgroundColor: darkGray, color: "white", textTransform: "uppercase", textAlign: "center", marginTop: "30px" }}><strong>Vuoi vendere la tua auto velocemente e senza rischi?</strong></h4>
            <p>
                Auto LG  effettuerà una perizia ed una valutazione della tua vettura.
                Qualora tu la accettassi, pagherà immediatamente il prezzo stabilito e l'auto verrà direttamente venduta a noi.
                Compila il modulo per ricevere una valutazione gratuita del tuo veicolo.
            </p>
            <p>
                Ti ricontatteremo al più presto.
            </p>
            <p>
                Leggi su <a href="https://www.autoscout24.it/concessionari/auto-lg-di-cufone-luca/recensioni" rel="noopener noreferrer" target="_blank" style={{ color: "#585858" }}>Autoscout24</a> e <a href="https://www.google.it/search?source=hp&ei=KdebX6KgCciMlwSMrqOYDA&q=auto%20lg&oq=auto+lg&gs_lcp=CgZwc3ktYWIQAzICCAAyAggAMgIIADICCAAyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAo6CAgAELEDEIMBOgUIABCxA1DW-JYDWNb_lgNgroGXA2gAcAB4AIAB8geIAe4NkgEHMi41LjctMZgBAKABAaoBB2d3cy13aXqwAQA&sclient=psy-ab&ved=2ahUKEwjJtteAlNzsAhVGxYUKHf6-DqcQvS4wAHoECAsQKw&uact=5&npsic=0&rflfq=1&rlha=0&rllag=45580413,9264601,3065&tbm=lcl&rldimm=2316154086190096256&lqi=CgdhdXRvIGxnWhIKB2F1dG8gbGciB2F1dG8gbGc&phdesc=S5i-3BbA7xI&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10&rlst=f#lrd=0x4786bf1a7180aa0d:0x2024a1deb06e1f80,1,,,&rlfi=hd:;si:2316154086190096256,l,CgdhdXRvIGxnWhIKB2F1dG8gbGciB2F1dG8gbGc,y,S5i-3BbA7xI;mv:[[45.59068997731902,9.301542190673427],[45.590330022680966,9.301027809326571]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10" rel="noopener noreferrer" target="_blank" style={{ color: "#585858" }}>Google</a> cosa dicono gli altri clienti che hanno venduto o acquistato con noi!
            </p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <h4 style={{ paddingLeft: "10px", backgroundColor: darkGray, color: "white", textTransform: "uppercase", textAlign: "center" }}><strong>Informazioni di contatto</strong></h4>
                <Row>
                    <TextInputField controlId="name" label="Nome" placeholder="Il tuo nome" md="4" />
                    <TextInputField controlId="mail" label="Email" placeholder="La tua email" md="4" />
                    <TextInputField controlId="phone" label="Telefono" placeholder="Il tuo numero di telefono" md="4" />
                </Row>

                <h4 style={{ paddingLeft: "10px", backgroundColor: darkGray, color: "white", textTransform: "uppercase", textAlign: "center" }}><strong>Informazioni del veicolo da valutare</strong></h4>
                <Row>
                    <TextInputField controlId="marca" label="Marca e modello" placeholder="Marca e modello del veicolo" md="3" />
                    <TextInputField controlId="allestimento" label="Allestimento" placeholder="Allestimento del veicolo" md="3" />
                    <TextInputField controlId="cilindrata" label="Cilindrata" placeholder="Cilindrata del veicolo" md="3" />
                    <Form.Group md="4" controlId="alimentazione">
                        <Form.Label>Alimentazione</Form.Label>
                        <Form.Control as="select" custom>
                            <option>Benzina</option>
                            <option>Diesel</option>
                            <option>GPL</option>
                            <option>Metano</option>
                            <option>Elettrico</option>
                            <option>Ibrido</option>
                            <option>Altro</option>
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <TextInputField controlId="km" label="Chilometraggio" placeholder="Km percorsi dal veicolo" md="3" />
                    <TextInputField controlId="anno" label="Anno immatricolazione" placeholder="Anno immatricolazione" md="3" />
                    <TextInputField controlId="targa" label="Targa" placeholder="Targa del veicolo" md="3" />
                    <Form.Group md="4" controlId="cambio">
                        <Form.Label>Cambio</Form.Label>
                        <Form.Control as="select" custom>
                            <option>Automatico</option>
                            <option>Manuale</option>
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <TextInputField controlId="tagliando" label="Ultimo tagliando" placeholder="Anno dell'ultimo tagliando" md="3" />
                    <TextInputField controlId="colore" label="Colore" placeholder="Colore del veicolo" md="3" />
                    <Form.Group md="4" controlId="proprietari">
                        <Form.Label>Numero proprietari</Form.Label>
                        <Form.Control as="select" custom>
                            <option>Primo proprietario</option>
                            <option>Secondo proprietario</option>
                            <option>Terzo proprietario</option>
                            <option>Altro</option>
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <TextInputField controlId="classe" label="Classe ambientale" placeholder="Classe ambientale" md="3" />
                    <TextInputField controlId="richiesta" label="Cifra richiesta" placeholder="Cifra richiesta" md="3" />
                </Row>
                <Row>
                <Form.Group controlId="tipovendita">
                    <Form.Label>Tipo vendita</Form.Label>
                    <Form.Control as="select" custom>
                        <option>Vendita diretta</option>
                        <option>Conto vendita</option>
                    </Form.Control>
                </Form.Group>
                <OverlayTrigger trigger="click" placement="top" overlay={sellTypePopover} rootClose>
                    <Button style={{ color: "#212529", cursor: "pointer", backgroundColor: lightGray, border: "0px solid gray" }}>Non sai decidere? Clicca qui per scoprire i vantaggi delle due soluzioni</Button>
                </OverlayTrigger>
                </Row>
                <Form.Group controlId="info">
                    <Form.Label>Altre informazioni</Form.Label>
                    <Form.Control as="textarea" rows="3" />
                </Form.Group>
                <Form.Group style={{ marginTop: "30px" }}>
                    <Form.Check
                        required
                        label={
                            <OverlayTrigger trigger="click" placement="top" overlay={privacyPopover} rootClose>
                                <Button style={{ color: "#212529", cursor: "pointer", backgroundColor: lightGray, border: "0px solid gray" }}>Ho preso visione e accetto l'informativa privacy</Button>
                            </OverlayTrigger>
                        }
                    />
                </Form.Group>

                <Button style={{ marginTop: "40px", backgroundColor: darkGray }} variant="secondary" type="submit">
                    <strong>INVIA RICHIESTA</strong>
                </Button>
            </Form>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Grazie!</Modal.Title>
                </Modal.Header>
                <Modal.Body>La tua richiesta è stata inviata correttamente! Ti ricontatteremo al più presto</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showError} onHide={handleCloseError}>
                <Modal.Header closeButton>
                    <Modal.Title>Ops...</Modal.Title>
                </Modal.Header>
                <Modal.Body>E' stato riscontrato un problema tecnico nell'invo della mail. Vi preghiamo di contattarci via mail o per telefono. Grazie.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseError}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default WeBuyPage;