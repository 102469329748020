import React from 'react'
import {strapiUrl} from '../../conf'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import vendutaImg from '../../assets/venduta_cover.jpg'



const gallery = (props) => {
    const images = [];
    const coverToShow = props.car.venduta ? vendutaImg : strapiUrl + props.car.cover.url
    const coverToShowThumbnail = props.car.venduta ? vendutaImg : strapiUrl + props.car.cover.formats.thumbnail.url
    images.push(
      {
        original: coverToShow,
        thumbnail: coverToShowThumbnail,
      }
    )
    props.car.gallery.forEach(img => {
      images.push({
        original: strapiUrl + img.url,
        thumbnail: strapiUrl + img.formats.thumbnail.url,
        sizes: "(min-width: 400px) 400px, 100vw"
      })
    })

    return (
          <ImageGallery items={images} showPlayButton={false}/>
    );
}

export default gallery;