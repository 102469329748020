import React from 'react';
import { Form, Button, Col } from 'react-bootstrap'
import {darkGray} from '../../conf'

const filters = (props) => {

    const handleClick = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        const filters = {
            tipo: form.tipo.value,
            alimentazione: form.alimentazione.value,
            cambio: form.cambio.value,
            prezzo: form.prezzo.value,
            km: form.km.value,
            anno: form.anno.value
        }
        props.applyFilter(filters)
    }

    return (
        <Form style={{ marginTop: "60px", marginBottom: "150px",  border: "1px solid #eaeaea", padding: "10px"}} onSubmit={handleClick}>
            <h3 style={{ textAlign: "center", fontFamily: "HelveticaNeue-CondensedBold", fontWeight: "normal", color: "#333333", textTransform: "uppercase" }}>
                come vorresti la tua nuova auto?
            </h3>
            <Form.Group as={Col} controlId="tipo">
                <Form.Label><strong>Tipologia</strong></Form.Label>
                <Form.Control as="select">
                    <option>Seleziona</option>
                    <option>Usato</option>
                    <option>Km 0</option>
                    <option>Aziendale</option>
                </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="alimentazione">
                <Form.Label><strong>Alimentazione</strong></Form.Label>
                <Form.Control as="select">
                    <option>Seleziona</option>
                    <option>Benzina</option>
                    <option>Diesel</option>
                    <option>Metano</option>
                    <option>GPL</option>
                    <option>Ibrido</option>
                    <option>Elettrico</option>
                    <option>Altro</option>
                </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="cambio">
                <Form.Label><strong>Cambio</strong></Form.Label>
                <Form.Control as="select">
                    <option>Seleziona</option>
                    <option>Manuale</option>
                    <option>Automatico</option>
                </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="prezzo">
                <Form.Label><strong>Prezzo massimo</strong></Form.Label>
                <Form.Control as="select">
                    <option>Seleziona</option>
                    <option>5.000</option>
                    <option>10.000</option>
                    <option>15.000</option>
                    <option>20.000</option>
                    <option>25.000</option>
                    <option>30.000</option>
                    <option>35.000</option>
                    <option>40.000</option>
                    <option>45.000</option>
                    <option>50.000</option>
                </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="km">
                <Form.Label><strong>Km massimo</strong></Form.Label>
                <Form.Control as="select">
                    <option>Seleziona</option>
                    <option>5.000 km</option>
                    <option>10.000 km</option>
                    <option>20.000 km</option>
                    <option>30.000 km</option>
                    <option>40.000 km</option>
                    <option>50.000 km</option>
                    <option>60.000 km</option>
                    <option>70.000 km</option>
                    <option>80.000 km</option>
                    <option>90.000 km</option>
                    <option>100.000 km</option>
                </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="anno">
                <Form.Label><strong>Anno da</strong></Form.Label>
                <Form.Control as="select">
                    <option>Seleziona</option>
                    <option>2020</option>
                    <option>2019</option>
                    <option>2018</option>
                    <option>2017</option>
                    <option>2016</option>
                    <option>2015</option>
                    <option>2014</option>
                    <option>2013</option>
                    <option>2012</option>
                    <option>2011</option>
                    <option>2010</option>
                    <option>2009</option>
                    <option>2008</option>
                    <option>2007</option>
                    <option>2005</option>
                    <option>2004</option>
                    <option>2003</option>
                    <option>2002</option>
                    <option>2001</option>
                    <option>2000</option>
                </Form.Control>
            </Form.Group>

            <div className="text-center">
                <Button variant="secondary" type="submit" style={{ width: "100%", backgroundColor: darkGray, border: "1px solid #B3B3B3" }}>
                    <strong>FILTRA AUTO</strong>
            </Button>
            </div>
        </Form>
    );
}

export default filters;