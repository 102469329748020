import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import {darkGray} from '../../conf'

const equipmentCard = (props) => {
    const equipments = Object.keys(props.equipment.eq)
        .filter((key) => props.equipment.eq[key] && key !== 'id')
        .map((key) => <p style={{borderBottom: "1px solid #F1F1F2"}} key={key}>{key.replace(/_/g, ' ')}</p>)
    let equipmentsFirstPart;
    let equipmentsSecondPart;

    if (equipments.length % 2 === 0) {
        equipmentsFirstPart = equipments.slice(0, equipments.length / 2)
        equipmentsSecondPart = equipments.slice(equipments.length / 2)
    }
    else {
        equipmentsFirstPart = equipments.slice(0, (equipments.length / 2) + 1)
        equipmentsSecondPart = equipments.slice((equipments.length / 2) + 1)
    }

    return (
        <div style={{ marginBottom: "30px" }}>
            <h4 style={{backgroundColor: darkGray, color: "white", width: "100%", textAlign: "center"}}>{props.equipment.title}</h4>
            <Container>
                <Row style={{ marginTop: "20px" }}>
                    <Col sm={6}>
                        {equipmentsFirstPart}
                    </Col>
                    <Col sm={6}>
                        {equipmentsSecondPart}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default equipmentCard;