import React from 'react'
import Popover from 'react-bootstrap/Popover'

const privacyPopover = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Informativa privacy</Popover.Title>
        <Popover.Content>
            Il Sistema prevede l’inserimento da parte del Cliente di alcuni dati personali, utilizzati da AutoLG ai fini della gestione operativa dei servizi e di informazioni commerciali ai clienti.
            Il titolare del trattamento è AutoLG, con sede legale in Via Fogazzaro 4, Monza, 20900.
            Informiamo il Cliente che i dati raccolti verranno trattati ai sensi e nel rispetto di quanto previsto dal D. lgs. 196/03 (“Codice in materia di protezione dei dati personali”) e verranno elaborati mediante strumenti (automatizzati e manuali) idonei a garantirne la sicurezza e la riservatezza. I dati raccolti verranno comunicati ai soggetti ai quali il trasferimento dei dati stessi risulti necessario, funzionale e strumentale alla gestione operativa dell’acquisto, ed ad informare i clienti delle azioni promozionali previste dell’azienda titolare del trattamento o di aziende partner. I dati raccolti non saranno oggetto di diffusione da parte di AutoLG.
      </Popover.Content>
    </Popover>
);

export default privacyPopover;